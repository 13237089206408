/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { InputCustom } from "../../../shared/ui/forms/input/InputCustom";

export function ActionsAmountFormatter(cellContent, row, rowIndex, { formik }) {
	const data = {
		name: "prices[" + rowIndex + "].amount",
		format: "number",
	};
	return (
		<>
			<span>
				<InputCustom
          rightTemplate={"€"}
          alignRight={true}
					ele={data}
					val={formik.values.prices[rowIndex].amount}
					changeValue={(value) => formik.setFieldValue(data.name, value)}
				/>
			</span>
		</>
	);
}
