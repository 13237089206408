import * as requestFromServer from "./customersCrud";
import { customerFetched, customersSlice, callTypes } from "./customersSlice";
import { gdaFetched } from "../gda/gdaSlice";
import { copyObject } from "../../app/utils/objUtils";
import {
	setErrorTypeAction,
	setSuccessErrorAction,
} from "../errors/errorsActions";
import { resetErrors } from "../errors/errorsSlice";
import { resetModal, setModal } from "../../app/shared/redux/modal/modalSlice";
import {getTypesCustomer} from "./customersCrud";

const { actions } = customersSlice;

export const fetchCustomers = () => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getAllCustomers()
		.then((response) => {
			dispatch(actions.customersFetched(response));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction("Errore nel caricamento dei clienti"));
		});
};

export const fetchCustomer = (id) => (dispatch) => {
	if (!id) {
		return dispatch(actions.customerFetched({ customerForEdit: undefined }));
	}

	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.getCustomerById(id)
		.then((res) => {
			dispatch(
				customerFetched({
					customerForEdit: { ...res.customer, "lp-sub": res["lp-sub"]?.id },
				})
			);
			dispatch(gdaFetched(res.gda));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction("Errore nel caricamento del cliente"));
		});
};

export const deleteCustomer = (id) => (dispatch) => {
	const buttonList = [
		{
			label: "Annulla",
			action: "cancelDelete",
			type: "secondary",
			dataType: {},
		},
		{
			label: "Conferma",
			action: "confirmDelete",
			type: "primary",
			dataType: {},
		},
	];
	const confirmDelete = () => {
		requestFromServer
			.deleteCustomer(id)
			.then((response) => {
				dispatch(actions.customerDeleted({ id }));
				dispatch(setSuccessErrorAction("Cliente cancellato con successo"));
			})
			.catch((error) => {
				dispatch(setErrorTypeAction("Errore nella cancellazione del cliente"));
			});
		dispatch(resetModal());
	};
	const cancelDelete = () => {
		dispatch(resetModal());
	};
	dispatch(
		setModal(
			buttonList,
			{
				title: "Cancella Cliente",
				body: `Sei sicuro di voler cancellare il cliente selezionato?`,
			},
			{
				cancelDelete,
				confirmDelete,
			}
		)
	);
};

export const createCustomerAction = (customerForCreation, callback) => (
	dispatch
) => {
	dispatch(resetErrors());
	const data = copyObject(customerForCreation);
	data.tipoCliente = 0;
	return requestFromServer
		.createCustomer(data)
		.then((response) => {
			callback && callback();
			dispatch(actions.customerCreated(response));
			dispatch(setSuccessErrorAction("Cliente salvato con successo"));
		})
		.catch((error) => {
			if(error?.response?.status === 409){
				dispatch(setErrorTypeAction("Codice cliente già esistente"));
			} else {
				dispatch(setErrorTypeAction("Errore nel salvataggio del cliente"));
			}
		});
};

export const updateCustomer = (customer) => (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.updateCustomer(customer)
		.then(() => {
			dispatch(actions.customerUpdated({ customer }));
			dispatch(setSuccessErrorAction("Cliente modificato con successo"));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction("Errore nella modifica del cliente"));
		});
};
export const getTypesCustomerAction = () => (dispatch) => {

	return getTypesCustomer().then((res) => {
			dispatch(actions.setTypesCustomer(res));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction("Errore nel caricamento dei tipi di clienti disponibili"));
		});
};
