export function UseAddCatalogToGda(addableCatalogs = []){
    const formStructureAddCatalog = [
        {
            name: 'idSub',
            label: 'Cataloghi',
            type: 'select',
            placeholder: 'Seleziona cataloghi',
            options: addableCatalogs.map(ele => ({value: ele.id, label: ele.name}))
        },
    ].map(ele => ({...ele, col: {sm:"6", lg:"3"}}))
    return {
        formStructureAddCatalog
    }
}
