import React, {useEffect} from "react";
import { useParams } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectSelectedProduct} from "../../../redux/products/productsSlice";
import {GetProductAction} from "../../../redux/products/productsActions";
import {CardDetailProduct} from "./CardDetailProduct";
import {CardDetailProductSubCatalogs} from "./CardDetailProductSubCatalogs";

export function ProductDetailPage({ history }) {
    const {id} = useParams();
    const dispatch = useDispatch();
    const product = useSelector(selectSelectedProduct)

    useEffect(() => {
        dispatch(GetProductAction(id));
    }, [id])



    return (
        <>{product &&
            <>
                <CardDetailProduct product={product} />
                <CardDetailProductSubCatalogs product={product}/>
            </>
        }
        </>

    );
}
