import React, {useState} from "react";
import {ShowHideForm} from "../../../shared/ui/forms/ShowHideForm";
import {FormCustomerCreate} from "./FormCustomerCreate";
import {configCloseForm} from "../../../shared/ui/forms/dataForm";

export function CustomerCreate() {
    const [config, setConfig]= useState();
  return (
      <>
          <ShowHideForm config={config} buttonLabel="Aggiungi Cliente">
            <FormCustomerCreate closeForm={() => setConfig(configCloseForm())} />
          </ShowHideForm>
      </>
  );
}
