export function UseAddProductToCatalog(products = []){
    const formStructureProduct = [
        {
            name: 'product',
            label: 'Prodotto',
            type: 'select',
            placeholder: 'Seleziona Prodotto',
            options: products.map(ele => ({value: ele.id, label: ele.title}))
        }
    ].map(ele => ({...ele, col: {sm:"6", lg:"3"}}))
    return {
        formStructureProduct
    }
}
