export const patients = "Patients";
export const clients = "Clients";
export const clientDetail = "ClientDetail";
export const gda = "gda";
export const products = "Products";
export const productDetail = "ProductDetail";
export const catalog = "Catalog";
export const catalogDetail = "CatalogDetail";
export const lp = "lp";
export const lpGenDetail = "lpGenDetail";
export const lpSubDetail = "lpSubDetail";
export const lpSubDetailValidity = "lpSubDetailValidity";
export const redirectPath = "/error/error-v1";
export const firstPage = "/clienti";
export const sample = "/sample";
