import React, {useEffect} from "react";
import { useParams } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {CatalogDetailEdit} from "./CatalogDetailEdit";
import {GetCatalogAction} from "../../../redux/catalogs/catalogsActions";
import {selectSelectedCatalog, selectSelectedProducts} from "../../../redux/catalogs/catalogsSlice";

export function CatalogDetailPage({ history }) {
    const {id} = useParams();
    const dispatch = useDispatch();
    const catalog = useSelector(selectSelectedCatalog)
    const selectedProducts = useSelector(selectSelectedProducts)


    useEffect(() => {
        dispatch(GetCatalogAction(id));
    }, [id])



    return (
        <div className="row">
            <div className="col-12">
                {catalog && (
                    <div>
                        <CatalogDetailEdit selectedProducts={selectedProducts} catalog={catalog}/>
                    </div>
                )}
            </div>
        </div>
    );
}
