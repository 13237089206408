export const path = "https://ae-linde-lsi-api.azurewebsites.net/api";
export const emailPath = "https://ae-linde-lsi-api.azurewebsites.net/api/mails/sendmails";

export const companies = [
    {
        CustomerName: "ASST PAVIA",
        CustomerAddress: "Viale Repubblica, 34",
        CustomerId: "M037984"
    }, {
        CustomerName: "ASL ROMA 5",
        CustomerAddress: "Via Acquaregna nn.1/15",
        CustomerId: "M002337"
    }, {
        CustomerName: "NOVARA",
        CustomerAddress: "Viale Roma, 7",
        CustomerId: "M013991"
    },{
        CustomerName: "ASST GRANDE OSPEDALE METROPOLITANO NIGUARDA",
        CustomerAddress: "Piazza Ospedale Maggiore, 3",
        CustomerId: "M034787"
    }
];

