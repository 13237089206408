import {createSelector, createSlice} from "@reduxjs/toolkit";

const initialCustomersState = {
  entities: [],
  types: [],
  categories: [],
  templates: [],
  templatesRdo: [],
  selectedProduct: undefined
};


export const productsSlice = createSlice({
  name: "products",
  initialState: initialCustomersState,
  reducers: {
    // listProducts
    setProducts: (state, action) => {
      state.entities = action.payload;
    },
    setTemplates: (state, action) => {
      state.templates = action.payload;
    },
    setTemplatesRdo: (state, action) => {
      state.templatesRdo = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setTypes: (state, action) => {
      state.types = action.payload;
    },
    addProduct: (state, action) => {
      state.entities = [...state.entities, action.payload];
    },
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
    addCatalogToSelectedProduct: (state, action) => {
      state.selectedProduct = {...state.selectedProduct, cSubs: [...state.selectedProduct.cSubs, action.payload] };
    },
    removeCatalogToSelectedProduct: (state, action) => {
      state.selectedProduct = {...state.selectedProduct, cSubs: state.selectedProduct.cSubs.filter(ele => ele.id !== action.payload.id)};
    },
    updateProduct: (state, action) => {
      state.selectedProduct = action.payload;
      state.entities = state.entities.map(ele => ele.id === action.payload.id ? action.payload : ele)
    },
    removeProduct: (state, action) => {
      state.entities = state.entities.filter(ele => ele.id !== action.payload)
    }
  }
});

export const {setProducts, setTemplatesRdo, removeCatalogToSelectedProduct, addCatalogToSelectedProduct, addProduct, setSelectedProduct, updateProduct, setTypes, setCategories, setTemplates, removeProduct} = productsSlice.actions;

export const selectListProducts = (state) => state.products.entities;
export const selectSelectedProduct = (state) => state.products.selectedProduct;
export const selectTypes = (state) => state.products.types;
export const selectCategories = (state) => state.products.categories;
export const selectTemplates = (state) => state.products.templates;
export const selectTemplatesRdo = (state) => state.products.templatesRdo;

