export function UseCreateProduct(types = [], categories = [], templates = [], templatesRdo = []) {
	const formStructureProduct = [
		{
			name: "codeSG",
			label: "Codice SG",
			type: "input",
			col: { sm: "6", lg: "3" },
		},
		{
			name: "codeERP",
			label: "Codice Articolo",
			type: "input",
			col: { sm: "6", lg: "3" },
		},
		{
			name: "category",
			label: "Categoria",
			type: "select",
			options: categories.map(({ id: value, label }) => ({ value, label })),
			placeholder: "Seleziona categoria",
			col: { sm: "6", lg: "3" },
		},
		{
			name: "type",
			label: "Tipo",
			type: "select",
			options: types.map(({ id: value, label }) => ({ value, label })),
			placeholder: "Seleziona tipologia",
			col: { sm: "6", lg: "3" },
		},
		{
			name: "title",
			label: "Titolo",
			type: "input",
			col: { sm: "6", lg: "3" },
		},
		{
			name: "template",
			label: "Template Prodotto",
			type: "select",
			options: templates.map(({ id: value, label }) => ({ value, label })),
			placeholder: "Seleziona template",
			col: { sm: "6", lg: "3" },
		},
		{
			name: "templateRdo",
			label: "Template Ordine",
			type: "select",
			options: templatesRdo.map(({ id: value, label }) => ({ value, label })),
			placeholder: "No",
			col: { sm: "6", lg: "3" },
		},
		{
			name: "description",
			label: "Descrizione",
			type: "textarea",
			col: { sm: "12", lg: "4" },
		},
		{
			name: "descriptionSummary",
			label: "Descrizione Sintetica",
			type: "textarea",
			col: { sm: "12", lg: "4" },
		},
		{
			name: "note",
			label: "Note",
			type: "textarea",
			col: { sm: "12", lg: "4" },
		},
		{
			name: "img",
			label: "Immagine",
			type: "input",
			dataElement: {
				type: "file",
				accept: "image/*",
			},
			col: { sm: "6", lg: "3" },
		},{
			name: "actived",
			label: "Attivo",
			type: "radio",
			format: "boolean",
			options: [
				{
					label: "Si",
					value: true,
				},
				{
					label: "No",
					value: false,
				},
			],
			col: { sm: "6", lg: "3" },
		},
		{
			name: "specifiedQuantity",
			label: "Specificabile la quantità",
			type: "radio",
			format: "boolean",
			options: [
				{
					label: "Si",
					value: true,
				},
				{
					label: "No",
					value: false,
				},
			],
			col: { sm: "6", lg: "3" },
		},
	];
	return {
		formStructureProduct,
	};
}
