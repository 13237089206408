import http from "../../app/utils/http";

export const PRODUCTS_URL = "products";


export const getProducts = () => http.get(`${PRODUCTS_URL}`)
export const getProduct = (id) => http.get(`${PRODUCTS_URL}/${id}`)
export const createProduct = (data) => http.post(`${PRODUCTS_URL}`, data)
export const updateProductCall = (data) => http.patch(`${PRODUCTS_URL}/${data.id}`, data)
export const deleteProductCall = (id) => http.delete(`${PRODUCTS_URL}/${id}`)
export const getListType = () => http.get(`types`)
export const getListTemplates = () => http.get(`templates`)
export const getListTemplatesRdo = () => http.get(`rdo_templates`)
export const getListCategories = () => http.get(`categories`)
