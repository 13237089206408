import React from "react";
import {useFormik} from "formik";
import {Col, Form, Row} from "react-bootstrap";
import {FormElements} from "../../../../shared/ui/forms/FormElements";
import SecondaryButton from "../../../../shared/ui/buttons/secondary-button/SecondaryButton";
import PrimaryButton from "../../../../shared/ui/buttons/primary-button/PrimaryButton";
import {UseAddCatalogToGda} from "./UseAddCatalogToGda";
import {validationSchemaGdaAddCatalog} from "./gdaCatalogTableData";



export function FormAddCatalogToGda({catalogos = [],  addCatalog, closeForm}) {
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchemaGdaAddCatalog,
        onSubmit: values => {
             addCatalog(values, () => closeForm());
        }
    });
    const {formStructureAddCatalog} = UseAddCatalogToGda(catalogos)
  return (
          <Form onSubmit={formik.handleSubmit}>
              <FormElements structure={formStructureAddCatalog} formik={formik}></FormElements>
              <Row className="justify-content-end mb-3">
                  <Col xs="auto">
                      <SecondaryButton type="button" onClick={() => closeForm()}>
                          Annulla
                      </SecondaryButton>
                  </Col>
                  <Col xs="auto">
                      <PrimaryButton type="submit">Aggiungi</PrimaryButton>
                  </Col>
              </Row>
          </Form>
  );
}
