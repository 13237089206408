import React, {useEffect} from "react";

import {CardCustom} from "../../shared/ui/container/CardCustom";
import {AddCatalogToProduct} from "./AddCatalogToProduct";
import {SelectedCatalogsTable} from "./SelectedCatalogsTable";
import {GetAllCatalogs} from "../../../redux/catalogs/catalogsActions";
import {useDispatch} from "react-redux";

export function CardDetailProductSubCatalogs({  product }) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(GetAllCatalogs())
    }, [])
    return (
        <CardCustom
            title={"Cataloghi associati a " + product.title}
            >
            <AddCatalogToProduct />
            <SelectedCatalogsTable catalogs={product.cSubs} />
        </CardCustom>
    );
}
