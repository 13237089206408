import userManager from "../app/utils/oidcUserManager";

export default function setupAxios(axios, store) {

  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use(
    response => {
      if (response.data.errors && response.data.errors[0].extensions.code === "AUTH_NOT_AUTHORIZED") {
        userManager.signoutRedirect();
      } else {
        return response;
      }
    },
    error => {
      if (error.response.status === 401) {
        //window.location.href = "/logout";
        userManager.signoutRedirect();
      } else {
        return Promise.reject(error);
      }
    });
}
