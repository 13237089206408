import React, {useState} from "react";
import {configCloseForm} from "../../shared/ui/forms/dataForm";
import {ShowHideForm} from "../../shared/ui/forms/ShowHideForm";
import {FormAddCatalogToProduct} from "./FormAddCatalogToProduct";

export function AddCatalogToProduct() {
    const [config, setConfig]= useState();
  return (
      <ShowHideForm config={config} buttonLabel="Aggiungi Catalogo">
          <FormAddCatalogToProduct closeForm={() => setConfig(configCloseForm())} />
      </ShowHideForm>
  );
}
