import React, {useState} from "react";
import {ShowHideForm} from "../../../../shared/ui/forms/ShowHideForm";
import {configCloseForm} from "../../../../shared/ui/forms/dataForm";
import {FormAddCatalogToGda} from "./FormAddCatalogToGda";



export function AddCatalogToGda({catalogos = [], addCatalog}) {
    const [config, setConfig]= useState();
  return (
      <ShowHideForm config={config} buttonLabel="Aggiungi Catalogo">
          <FormAddCatalogToGda catalogos={catalogos} addCatalog={addCatalog} closeForm={() => setConfig(configCloseForm())} />
      </ShowHideForm>
  );
}
