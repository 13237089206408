import React, {useState} from "react";
import {ShowHideForm} from "../../shared/ui/forms/ShowHideForm";
import {FormCreateCatalog} from "./FormCreateCatalog";
import {configCloseForm} from "../../shared/ui/forms/dataForm";


export function CreateCatalog() {
    const [config, setConfig]= useState();
  return (
      <ShowHideForm config={config} buttonLabel="Aggiungi Catalogo">
          <FormCreateCatalog closeForm={() => setConfig(configCloseForm())} />
      </ShowHideForm>
  );
}
