import React from "react";
import BootstrapTable from "react-bootstrap-table-next";

import {NoRecordsFoundMessage, PleaseWaitMessage} from "../../../_metronic/_helpers";
import {columnsCatalogs} from "../catalogs/catalogsData";
import {DeleteColumnFormatter} from "../../component/CellRowTable/DeleteColumnFormatter";
import {RemoveCatalogToProductAction} from "../../../redux/products/productsActions";
import {useDispatch} from "react-redux";

export function SelectedCatalogsTable({catalogs}) {
    const dispatch = useDispatch();
  const getSelectRow = (props) => {
    return {
      mode: "checkbox",
      clickToSelect: true,
      hideSelectAll: true,
      hideSelectColumn: true,
      onSelect: (row, isSelect, rowIndex, e) => {
      },
    };
  }
  const removeCatalog = (row) => {
      dispatch(RemoveCatalogToProductAction(row.id))
  }
  const dataTable = [
      ...columnsCatalogs,
      {
          dataField: "delete",
          text: "",
          formatter: DeleteColumnFormatter,
          formatExtraData: {
              deleteRow: removeCatalog,
          },
          classes: "",
          style: {
              maxWidth: "100px",
          },
      }
  ]
  return (
    <>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                hover={false}
                keyField="id"
                data={catalogs}
                columns={dataTable}
                selectRow={getSelectRow({
                  catalogs
                })}
              >
                <PleaseWaitMessage entities={catalogs} />
                <NoRecordsFoundMessage entities={catalogs} />
              </BootstrapTable>
    </>
  );
}
