import * as Yup from "yup";
export const columnsProducts = [
    {
        dataField: "codeSG",
        text: "Codice SG"
    },
    {
        dataField: "codeERP",
        text: "Codice Articolo"
    },
    {
        dataField: "title",
        text: "Nome"
    },
    {
        dataField: "descriptionSummary",
        text: "Descrizione"
    },
    {
        dataField: "category",
        text: "Categoria"
    },
    {
        dataField: "type",
        text: "Tipo"
    },
];

export const validationSchemaProduct = Yup.object().shape({
    codeSG: Yup.string().required('Codice SG obbligatorio'),
    codeERP: Yup.string().required('Codice articolo obbligatorio'),
    category: Yup.string().required('La categoria è obbligatoria'),
    type: Yup.string().required('Tipo obbligatorio'),
    title: Yup.string().required('Il titolo è obbligatorio'),
    description: Yup.string().required('La descrizione è obbligatoria'),
    descriptionSummary: Yup.string().required('La descrizione è obbligatoria'),
    template: Yup.string().required('Il template è obbligatorio'),
    actived: Yup.string().required('Il campo è obbligatorio'),
    specifiedQuantity: Yup.string().required('Il campo è obbligatorio')
})


export const validationSchemaProductAddCatalog = Yup.object().shape({
    catalog: Yup.string().required('Seleziona un catalogo da associare')
});
