export const formStructureGdaFirst = [
	{
		name: "name",
		label: "Nome",
		type: "input",
		col: { sm: "6", lg: "3" },
	}
];

export const formStructureGdaSecond = [
	{
		name: "state",
		label: "Stato",
		type: "radio",
		format: "boolean",
		options: [
			{
				label: "Attivo",
				value: true,
			},
			{
				label: "Disattivo",
				value: false,
			},
		],
		col: { sm: "6", lg: "2" },
	},
	{
		name: "showPrice",
		label: "Mostra prezzi",
		type: "radio",
		format: "boolean",
		options: [
			{
				label: "Si",
				value: true,
			},
			{
				label: "No",
				value: false,
			},
		],
		col: { sm: "6", lg: "2" },
	},
	{
		name: "authRdo",
		label: "Richiesta autorizzazione per acquisto prodotti",
		type: "radio",
		format: "boolean",
		options: [
			{
				label: "Si",
				value: true,
			},
			{
				label: "No",
				value: false,
			},
		],
		col: { sm: "6", lg: "2" },
	},
	{
		name: "description",
		label: "Breve Descrizione",
		type: "textarea",
		col: { sm: "12", lg: "6" },
	},
	{
		name: "note",
		label: "Note",
		type: "textarea",
		col: { sm: "12", lg: "6" },
	},
];
