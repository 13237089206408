import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import { FormElements } from "../../shared/ui/forms/FormElements";
import PrimaryButton from "../../shared/ui/buttons/primary-button/PrimaryButton";
import {
	GetListCategoriesAction,
	GetListTemplateAction, GetListTemplateRdoAction,
	GetListTypeAction,
	UpdateProductAction,
} from "../../../redux/products/productsActions";
import { UseCreateProduct } from "./UseCreateProduct";
import {
	selectCategories,
	selectTemplates, selectTemplatesRdo,
	selectTypes,
} from "../../../redux/products/productsSlice";
import {getBase64} from "../../utils/fileUtils";
import {validationSchemaProduct} from "./productsData";

export function ProductDetailEdit({ product }) {
	const dispatch = useDispatch();


	const types = useSelector(selectTypes);
	const categories = useSelector(selectCategories);
	const templates = useSelector(selectTemplates);
	const templatesRdo = useSelector(selectTemplatesRdo);
	useEffect(() => {
		dispatch(GetListTypeAction());
		dispatch(GetListTemplateAction());
		dispatch(GetListCategoriesAction());
		dispatch(GetListTemplateRdoAction());
	}, []);
	const formik = useFormik({
		initialValues: {},
		validationSchema: validationSchemaProduct,
		onSubmit: (values) => {
			if(values.img && values.img.file){
				getBase64(values.img.file).then(img => {
					const copy = {...values, img}
					dispatch(UpdateProductAction(copy))
				})
			}else{
				dispatch(UpdateProductAction(values))
			}
		},
	});
	useEffect(() => {
		product && formik.setValues(product);
	}, [product]);
	const { formStructureProduct } = UseCreateProduct(
		types,
		categories,
		templates,
		templatesRdo
	);
	return (
			<Form onSubmit={formik.handleSubmit}>
				<FormElements
					structure={formStructureProduct}
					formik={formik}></FormElements>
				<Row className="justify-content-end">
					<Col xs="auto">
						<PrimaryButton type="submit">Aggiorna</PrimaryButton>
					</Col>
				</Row>
			</Form>
	);
}
