import React from "react";
import MultiSelect from "react-multi-select-component";
export function MultiSelectForm({ ele, formik }) {
	console.log(formik.values);
	let valueMulti = ele.options.filter((el) => (formik.values[ele.name] || []).indexOf(el.value) !== -1);
	console.log(valueMulti);
	let changeHandler = (nextValues) => {
		const nextSelectedValues = (ele.disableMultipleSelect
				? nextValues.filter((nextValue) => !valueMulti.includes(nextValue))
				: nextValues
		).map((ele) => ele.value);
		console.log(ele.name, nextSelectedValues);
		formik.setFieldValue(ele.name, nextSelectedValues);
	};
	return (
		<MultiSelect
			className="custom-select-multi"
			selectAllLabel={"Seleziona tutti"}
			options={ele.options}
			value={valueMulti}
			onChange={changeHandler}
			labelledBy={ele.placeholder || 'Seleziona elemento'}
			hasSelectAll={!ele.disableMultipleSelect}
			overrideStrings={{
				selectSomeItems: ele.placeholder || 'Seleziona elemento',
				allItemsAreSelected: "Tutti",
				selectAll: "Seleziona tutti",
				search: "Cerca",
				clearSearch: "Pulisci ricerca",
			}}
		/>
	);
}
