import React from "react";
import { Col, InputGroup, Row } from "react-bootstrap";

export function RadioForm({ ele, formik }) {
	return (
		<Row>
			{ele.options.map((opt) => (
				<Col xs="auto" {...(opt.col || {})} key={opt.label}>
					<InputGroup className="shared-radio">
						<InputGroup.Radio
							checked={formik.values[ele.name] === opt.value}
							className="radio-sh"
							value={opt.value}
							name={ele.name}
							onChange={(data) =>
								formik.setFieldValue(
									ele.name,
									ele.format === "boolean"
										? data.target.value === "true"
										: data.target.value
								)
							}></InputGroup.Radio>
						<label>{opt.label}</label>
					</InputGroup>
				</Col>
			))}
		</Row>
	);
}
