import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	addCatalogGda,
	addUserGda,
	fetchAllAddableUserGda,
	fetchGda,
	getAllRolesAction, removeCatalogGda,
	removeUserGda, updateGdaAction,
} from "../../../../redux/gda/gdaActions";
import {
	resetGdaAddableUsers,
	resetSelectedGda, selectAddableCatalogs,
	selectAddableUsers,
	selectRoles,
	selectSelectedGda,
} from "../../../../redux/gda/gdaSlice";
import { Col, Form, Row } from "react-bootstrap";
import { FormElements } from "../../../shared/ui/forms/FormElements";
import {
	formStructureGda,
} from "../../customers/show/customerData";
import PrimaryButton from "../../../shared/ui/buttons/primary-button/PrimaryButton";
import { CardCustom } from "../../../shared/ui/container/CardCustom";
import { useFormik } from "formik";
import { GdaUsersTable } from "./users/GdaUsersTable";
import BackBtn from "../../../shared/ui/backBtn/BackBtn";
import * as routeName from "../../../routes/routeNameAuthType";
import { useRoute } from "../../../utils/useRoute";
import {validationSchemaGda} from "../../customers/index/customerData";
import {UseCustomerDetailCreateGda} from "../../customers/show/UseCustomerDetailCreateGda";
import {GetListTemplateRdoAction} from "../../../../redux/products/productsActions";
import {selectTemplatesRdo} from "../../../../redux/products/productsSlice";
import {GdaCatalogsTable} from "./catalogs/GdaCatalogsTable";
import {GetAllCatalogs} from "../../../../redux/catalogs/catalogsActions";
import {selectListCatalogs} from "../../../../redux/catalogs/catalogsSlice";
import {objDeleteProperties} from "../../../utils/objUtils";

export function CustomerGdaPage({ history }) {
	const { id, idGda } = useParams();
	const { gda, users } = useSelector(selectSelectedGda) || {};
	const addableUsers = useSelector(selectAddableUsers);
	const addableCatalogos = useSelector(selectAddableCatalogs)
	const roles = useSelector(selectRoles);
	const templatesRdo = useSelector(selectTemplatesRdo);
	const dispatch = useDispatch();
	const { changeRoute } = useRoute();

	const formik = useFormik({
		initialValues: {},
		validationSchema: validationSchemaGda,
		onSubmit: (values= {}) => {
			dispatch(updateGdaAction(id, idGda, objDeleteProperties(values, ['subCatalogos'])))
		},
	});

	useEffect(() => {
		dispatch(fetchGda(id, idGda));
		dispatch(fetchAllAddableUserGda(id, idGda));
		dispatch(getAllRolesAction());
		dispatch(GetListTemplateRdoAction());
		dispatch(GetAllCatalogs());
		return () => {
			dispatch(resetSelectedGda());
			dispatch(resetGdaAddableUsers());
		};
	}, []);

	useEffect(() => {
		gda && formik.setValues(gda);
	}, [gda]);
	const addUser = (row, callback) => {
		dispatch(addUserGda(id, idGda, row, callback));
	};
	const addCatalog = ({idSub}, callback) => {
		 dispatch(addCatalogGda(idGda, idSub, callback));
	};
	const removeCatalog = (row, callback) => {
		dispatch(removeCatalogGda(idGda, row.id, callback));
	};
	const removeUser = (row) => {
		dispatch(removeUserGda(id, idGda, row));
	};
	const {formStructure} = UseCustomerDetailCreateGda(templatesRdo)
	return (
		<div className="row">
			<div className="col-12">
				<CardCustom
					title={"Gruppo di acquisto " + gda?.name}
					rightTemplate={
						<BackBtn
							label="Torna a lista clienti"
							clickHandler={() => changeRoute(routeName.clients)}
						/>
					}>
					<Form onSubmit={formik.handleSubmit}>
						<FormElements
							structure={formStructure}
							formik={formik}></FormElements>
						<Row className="justify-content-end mb-3">
							<Col xs="auto">
								<PrimaryButton type="submit">Aggiorna</PrimaryButton>
							</Col>
						</Row>
					</Form>

				</CardCustom>
				<CardCustom title={"Utenti"}>
					<GdaUsersTable
						users={addableUsers}
						selectedUsers={users}
						roles={roles}
						addUser={addUser}
						removeUser={removeUser}
					/>
				</CardCustom>
				<CardCustom title={"SubCataloghi"}>
					<GdaCatalogsTable
						catalogos={addableCatalogos}
						selectedCatalogos={gda?.subCatalogos || []}
						addCatalog={addCatalog}
						removeCatalog={removeCatalog}
					/>
				</CardCustom>
			</div>
		</div>
	);
}
