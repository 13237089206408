/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

export function TFColumnFormatter(
	cellContent,
	row,
	rowIndex,
	{ labelTrue, labelFalse, colName }
) {
	return (
		<>
			{row[colName] !== undefined
				? row[colName] === true
					? labelTrue
					: labelFalse
				: "-"}
		</>
	);
}
