import React from "react";
import {useFormik} from "formik";
import {Col, Form, Row} from "react-bootstrap";
import {FormElements} from "../../../../shared/ui/forms/FormElements";
import SecondaryButton from "../../../../shared/ui/buttons/secondary-button/SecondaryButton";
import PrimaryButton from "../../../../shared/ui/buttons/primary-button/PrimaryButton";
import {UseAddUserToGda} from "./UseAddUserToGda";
import {validationSchemaGdaAddUser} from "./gdaUserTableData";



export function FormAddUserToGda({addableUsers = [], roles = [], addUser, closeForm}) {
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchemaGdaAddUser,
        onSubmit: values => {
            addUser(values, () => closeForm());
        }
    });
    const {formStructureAddUser} = UseAddUserToGda(addableUsers, roles)
  return (
          <Form onSubmit={formik.handleSubmit}>
              <FormElements structure={formStructureAddUser} formik={formik}></FormElements>
              <Row className="justify-content-end mb-3">
                  <Col xs="auto">
                      <SecondaryButton type="button" onClick={() => closeForm()}>
                          Annulla
                      </SecondaryButton>
                  </Col>
                  <Col xs="auto">
                      <PrimaryButton type="submit">Aggiungi</PrimaryButton>
                  </Col>
              </Row>
          </Form>
  );
}
