import React from "react";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";

export function CardCustom({ title, children, rightTemplate }) {
	return (
		<Card>
			<CardHeader>
				<div className="d-flex justify-content-between align-items-center w-100">
					<div className="card-title">{title}</div>
					<div>{rightTemplate ? rightTemplate : null}</div>
				</div>
			</CardHeader>
			<CardBody>{children}</CardBody>
		</Card>
	);
}
