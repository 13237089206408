import * as Yup from "yup";

export const columnsCatalogs = [
    {
        dataField: "name",
        text: "Nome"
    }
];


export const formStructureCatalog = [
    {
        name: 'name',
        label: 'Nome',
        type: 'input',
    },
    {
        name: 'actived',
        label: 'Attivo',
        type: 'radio',
        format: 'boolean',
        options: [
            {
                label: 'Si',
                value: true
            },
            {
                label: 'No',
                value: false
            }
        ]
    }
].map(ele => ({...ele, col: {sm:"6", lg:"3"}}))

export const validationSchemaCatalog = Yup.object().shape({
    name: Yup.string().required('Il nome è obbligatorio'),
    actived: Yup.string().required('Il campo è obbligatorio')
});

export const validationSchemaCatalogAddProduct = Yup.object().shape({
    product: Yup.string().required('Seleziona un prodotto da aggiungere')
});
