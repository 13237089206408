import React from "react";
import Button from "../button/Button";

const PrimaryButton = ({
  children,
  className,
  sizeBtn,
  variant = "primary",
  type = "submit",
  ...rest
}) => {
  return (
    <Button
      type={type}
      variant={variant}
      sizeBtn={sizeBtn}
      className={`sh-primary ${className}`}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
