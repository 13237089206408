import React from "react";

import {ProductDetailEdit} from "./ProductDetailEdit";
import BackBtn from "../../shared/ui/backBtn/BackBtn";
import * as routeName from "../../routes/routeNameAuthType";
import {CardCustom} from "../../shared/ui/container/CardCustom";
import {useRoute} from "../../utils/useRoute";

export function CardDetailProduct({  product }) {
    const { changeRoute } = useRoute();
    return (
        <CardCustom
            title={"Dettaglio Prodotto " + product.title}
            rightTemplate={
                <BackBtn
                    label="Torna alla lista prodotti"
                    clickHandler={() => changeRoute(routeName.products)}
                />
            }>

                <div className="row">
                    <div className="col-3">
                        {product.img ? <img style={{maxWidth: '100%'}} src={product.img}/> : <p>Nessuna immagine inserita</p>}
                    </div>
                    <div className="col-9">
                        <ProductDetailEdit product={product}/>
                    </div>
                </div>
        </CardCustom>
    );
}
