import { createSlice } from "@reduxjs/toolkit";

const initialUsersState = {
    customersList: [],
    selectedCustomer: undefined
};

export const usersSlice = createSlice({
    name: "users",
    initialState: initialUsersState,
    reducers: {
        usersCustomersFetched: (state, action) => {
            try {
                const { entities } = action.payload;
                state.customersList = entities;
            } catch (error) {
                console.error(error);
            }
        },
        setSelectedCustomer: (state, action) => {
            try {
                const { customer } = action.payload;
                localStorage.setItem("customerId", customer.CustomerId);
                state.selectedCustomer = action.payload;
            } catch (error) {
                console.error(error);
            }

        }
    }
});