import axios from "axios";
import {gdaMock} from "./mock/gdaMock";
import http from "../../app/utils/http";

export const CUSTOMERS_URL = "customers";

// CREATE =>  POST: add a new customer to the server
export function createGda(customerId, gda) {
  //return axios.post(CUSTOMERS_URL, { customer });
  return http.post(`${CUSTOMERS_URL}/${customerId}/gda`, gda)
}

// READ
export function getAllAddableUserGda(customerId, gdaId) {
  // il metodo ritorna gli utenti che possono essere aggiunti alla gda
  return http.get(`${CUSTOMERS_URL}/${customerId}/gda/${gdaId}/addableUsers`)
}

export function getAllRoles() {
  return http.get(`roles`)
}

export function getGdaById(customerId, gdaId) {
  return http.get(`${CUSTOMERS_URL}/${customerId}/gda/${gdaId}`)
}

export function addUserToGda(customerId, gdaId, idUser, roles = []) {
  return http.post(`${CUSTOMERS_URL}/${customerId}/gda/${gdaId}/user/${idUser}`, {roles})
}
export function addCatalogToGda(gdaId, idSub) {
  return http.post(`gda/${gdaId}/subcatalog/${idSub}`)
}

export function deleteCatalogToGdaCall(gdaId, idSub) {
  return http.delete(`gda/${gdaId}/subcatalog/${idSub}`)
}

export function removeUserToGda(customerId, gdaId, idUser) {
  return http.delete(`${CUSTOMERS_URL}/${customerId}/gda/${gdaId}/user/${idUser}`)
}

// UPDATE => PUT: update the customer on the server
export function updateGda(customerId, gdaId, data) {
  return http.put(`${CUSTOMERS_URL}/${customerId}/gda/${gdaId}`, data)
}

// DELETE => delete the customer from the server
export function deleteGda(customerId, gdaId) {
  return http.delete(`${CUSTOMERS_URL}/${customerId}/gda/${gdaId}`)
}

// DELETE Customers by ids
export function deleteMultiGda(ids) {
  //return axios.post(`${CUSTOMERS_URL}/deleteCustomers`, { ids });
}
