import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import * as routeName from "../../routes/routeNameAuthType";

import {columnsCatalogs} from "./catalogsData";
import {useRoute} from "../../utils/useRoute";
import {NoRecordsFoundMessage, PleaseWaitMessage} from "../../../_metronic/_helpers";
import {DeleteColumnFormatter} from "../../component/CellRowTable/DeleteColumnFormatter";
import {DeleteCatalogAction} from "../../../redux/catalogs/catalogsActions";
import {useDispatch} from "react-redux";

export function CatalogsTable({catalogs}) {
  const {changeRoute} = useRoute();
  const dispatch = useDispatch();
  const getSelectRow = (props) => {
    return {
      mode: "checkbox",
      clickToSelect: true,
      hideSelectAll: true,
      hideSelectColumn: true,
      onSelect: (row, isSelect, rowIndex, e) => {
        changeRoute(routeName.catalogDetail,  {id: row?.id})
      },
    };
  }
  const removeCatalog = (row) => {
    dispatch(DeleteCatalogAction(row.id))
  }
  const dataTable = [
      ...columnsCatalogs,
    {
      dataField: "delete",
      text: "",
      formatter: DeleteColumnFormatter,
      formatExtraData: {
        deleteRow: removeCatalog,
      },
      classes: "",
      style: {
        maxWidth: "100px",
      },
    }
  ]
  return (
    <>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                hover={true}
                keyField="id"
                data={catalogs}
                columns={dataTable}
                selectRow={getSelectRow({
                  catalogs
                })}
              >
                <PleaseWaitMessage entities={catalogs} />
                <NoRecordsFoundMessage entities={catalogs} />
              </BootstrapTable>
    </>
  );
}
