import React, {useState} from "react";
import {Card, CardBody, CardHeader} from "../../../_metronic/_partials/controls";
import {uploadUsers} from "./patientsCrud";
import {Col, Row} from "react-bootstrap";

export function PatientsPage({ history }) {
    const [loadPatients, setLoadPatients] = useState();
    const loadFile= (event) => {
        const formData = new FormData();
        formData.append(
            "file",
            event.target.files[0],
            event.target.files[0].name
        );
        uploadUsers(formData).then(res => {
            setLoadPatients(res.data);
        })
    }
  return (
      <div className="row">
        <div className="col-12">
          <Card>
            <CardHeader title="Caricamento Pazienti"/>
            <CardBody>
                <Row>
                    <Col sm={6}>
                        <input type="file" onChange={loadFile}/>
                    </Col>
                    {loadPatients && <Col sm={6}>
                        <div>Numero di caricamenti non riusciti: {loadPatients.numberRecordDiscarded}</div>
                        <div>Numero di pazienti creati: {loadPatients.numberRecordImported}</div>
                        <div>Numero di pazienti aggiornati: {loadPatients.numberRecordUpdated}</div>
                    </Col>}
                </Row>

                {loadPatients && <div>

                </div>}
            </CardBody>
          </Card>
        </div>
      </div>
  );
}
