/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex
  //{ openEditSpecificationDialog, openDeleteSpecificationDialog }
) {
  return (
    <>
        {(row.roles?.map(ele => ele.name) || []).join(', ')}
    </>
  );
}
