/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

export function DateColumnFormatter(cellContent, row, rowIndex, { titleCol }) {
	const date = new Date(row[titleCol]).toLocaleString("it-IT", {
		day: "2-digit",
		month: "short",
		year: "numeric",
	});
	return <>{date}</>;
}
