import React from "react";
import authRoute from "./routes/auth";
import DynamicRoute from "./routes/DynamicRoute";
import {usePermissions} from "./shared/utils/usePemissions";

export default function BasePage() {

  const {issetPermission} = usePermissions();
  return (
      <DynamicRoute routes={{
        ...authRoute,
        list: authRoute.list.filter((r) => r.permissions && r.permissions.length ? issetPermission(r.permissions) : true),
      }} />
  );
}
