import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import * as routeName from "../../routes/routeNameAuthType";

import {columnsProducts} from "./productsData";
import {useRoute} from "../../utils/useRoute";
import {NoRecordsFoundMessage, PleaseWaitMessage} from "../../../_metronic/_helpers";
import {DeleteColumnFormatter} from "../../component/CellRowTable/DeleteColumnFormatter";
import {useDispatch} from "react-redux";
import {DeleteProductAction} from "../../../redux/products/productsActions";

export function ProductsTable({products}) {
  const {changeRoute} = useRoute();
  const dispatch = useDispatch();
  const getSelectRow = (props) => {
    return {
      mode: "checkbox",
      clickToSelect: true,
      hideSelectAll: true,
      hideSelectColumn: true,
      onSelect: (row, isSelect, rowIndex, e) => {
        changeRoute(routeName.productDetail,  {id: row?.id})
      },
    };
  }
  const removeProduct = (row) => {
    dispatch(DeleteProductAction(row.id))
  }
  const dataTable = [
      ...columnsProducts,
    {
    	dataField: "delete",
    	text: "",
    	formatter: DeleteColumnFormatter,
    	formatExtraData: {
    		deleteRow: removeProduct,
    	},
    	classes: "",
    	style: {
    		maxWidth: "100px",
    	},
    },
  ]
  return (
    <>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                hover={true}
                keyField="id"
                data={products}
                columns={dataTable}
                selectRow={getSelectRow({
                  products
                })}
              >
                <PleaseWaitMessage entities={products} />
                <NoRecordsFoundMessage entities={products} />
              </BootstrapTable>
    </>
  );
}
