import React, {useState} from "react";
import {ShowHideForm} from "../../../shared/ui/forms/ShowHideForm";
import {FormCreateLpGen} from "./FormCreateLpGen";
import {configCloseForm} from "../../../shared/ui/forms/dataForm";


export function CreateLpGen() {
    const [config, setConfig]= useState();

  return (
      <ShowHideForm config={config} buttonLabel="Aggiungi Validità">
          <FormCreateLpGen closeForm={() => setConfig(configCloseForm())} />
      </ShowHideForm>
  );
}
