import * as requestFromServer from "./catalogsCrud";
import { objDeleteProperties } from "../../app/utils/objUtils";
import {
	addCatalog,
	addSelectedProducts,
	removeCatalog,
	removeSelectedProducts,
	setCatalogs,
	setSelectedCatalog,
	setSelectedProducts,
	updateCatalog,
} from "./catalogsSlice";
import {
	setErrorTypeAction,
	setSuccessErrorAction,
} from "../errors/errorsActions";
import { resetErrors } from "../errors/errorsSlice";
import { resetModal, setModal } from "../../app/shared/redux/modal/modalSlice";

export const GetAllCatalogs = () => (dispatch) => {
	return requestFromServer
		.getCatalogs()
		.then((response) => {
			dispatch(setCatalogs(response));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction("Errore nel caricamento dei cataloghi"));
		});
};

export const GetCatalogAction = (id) => (dispatch) => {
	return requestFromServer
		.getCatalog(id)
		.then((response) => {
			dispatch(setSelectedCatalog(response.subCatalog));
			dispatch(setSelectedProducts(response.products));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction("Errore nel caricamento del catalogo"));
		});
};

export const AddProductToCatalogAction = (id, callback) => (
	dispatch,
	getState
) => {
	dispatch(resetErrors());
	const catalogId = getState().catalogs.selectedCatalog.id;
	return requestFromServer
		.addProductToCatalogCall(catalogId, id)
		.then((response) => {
			callback && callback();
			const product = getState().products.entities.find(
				(prod) => prod.id === id
			);
			dispatch(addSelectedProducts(product));
			dispatch(setSuccessErrorAction("Prodotto inserito con successo"));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction("Errore nell'inserimento del prodotto"));
		});
};

export const RemoveProductToCatalogAction = (id) => (dispatch, getState) => {
	dispatch(resetErrors());
	const catalogId = getState().catalogs.selectedCatalog.id;

	const buttonList = [
		{
			label: "Annulla",
			action: "cancelDelete",
			type: "secondary",
			dataType: {},
		},
		{
			label: "Conferma",
			action: "confirmDelete",
			type: "primary",
			dataType: {},
		},
	];
	const confirmDelete = () => {
		requestFromServer
			.removeProductToCatalogCall(catalogId, id)
			.then((response) => {
				const product = getState().products.entities.find(
					(prod) => prod.id === id
				);
				dispatch(removeSelectedProducts(product));
				dispatch(setSuccessErrorAction("Prodotto disassociato con successo"));
			})
			.catch((error) => {
				dispatch(setErrorTypeAction("Errore nella dissociazione del prodotto"));
			});
		dispatch(resetModal());
	};
	const cancelDelete = () => {
		dispatch(resetModal());
	};
	dispatch(
		setModal(
			buttonList,
			{
				title: "Cancella Prodotto dal catalogo",
				body: `Sei sicuro di voler cancellare il prodotto selezionato dal catalogo?`,
			},
			{
				cancelDelete,
				confirmDelete,
			}
		)
	);
};

export const CreateCatalogAction = (data, callback) => (dispatch) => {
	dispatch(resetErrors());
	return requestFromServer
		.createCatalog(data)
		.then((response) => {
			dispatch(addCatalog(response));
			callback && callback();
			dispatch(setSuccessErrorAction("Catalogo creato con successo"));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction("Errore nel salvataggio del catalogo"));
		});
};

export const UpdateCatalogAction = (data, callback) => (dispatch) => {
	dispatch(resetErrors());
	const copyData = objDeleteProperties(data, ["infoUpdate"]);
	return requestFromServer
		.updateCatalogCall(copyData)
		.then((response) => {
			dispatch(updateCatalog(response));
			callback && callback();
			dispatch(setSuccessErrorAction("Catalogo modificato con successo"));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction("Errore nella modifica del catalogo"));
		});
};

export const DeleteCatalogAction = (id) => (dispatch) => {
	dispatch(resetErrors());
	const buttonList = [
		{
			label: "Annulla",
			action: "cancelDelete",
			type: "secondary",
			dataType: {},
		},
		{
			label: "Conferma",
			action: "confirmDelete",
			type: "primary",
			dataType: {},
		},
	];
	const confirmDelete = () => {
		requestFromServer
			.deleteCatalogCall(id)
			.then((response) => {
				dispatch(removeCatalog(id));
				dispatch(setSuccessErrorAction("Catalogo eliminato con successo"));
			})
			.catch((error) => {
				dispatch(setErrorTypeAction("Errore nell'eliminazione del catalogo"));
			});
		dispatch(resetModal());
	};
	const cancelDelete = () => {
		dispatch(resetModal());
	};
	dispatch(
		setModal(
			buttonList,
			{
				title: "Cancella catalogo",
				body: `Sei sicuro di voler cancellare il catalogo selezionato?`,
			},
			{
				cancelDelete,
				confirmDelete,
			}
		)
	);
};
