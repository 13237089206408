import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { CardCustom } from "../../shared/ui/container/CardCustom";
import { Col, Form, Row } from "react-bootstrap";
import { FormElements } from "../../shared/ui/forms/FormElements";
import PrimaryButton from "../../shared/ui/buttons/primary-button/PrimaryButton";
import {formStructureCatalog, validationSchemaCatalog} from "./catalogsData";
import { SelectedProductsTable } from "./SelectedProductsTable";
import { AddProductToCatalog } from "./AddProductToCatalog";
import BackBtn from "../../shared/ui/backBtn/BackBtn";
import { useRoute } from "../../utils/useRoute";
import * as routeName from "../../routes/routeNameAuthType";
import {UpdateCatalogAction} from "../../../redux/catalogs/catalogsActions";
import {GetAllProducts} from "../../../redux/products/productsActions";

export function CatalogDetailEdit({ catalog, selectedProducts }) {
	const dispatch = useDispatch();
	const { changeRoute } = useRoute();

	const formik = useFormik({
		initialValues: {},
		validationSchema: validationSchemaCatalog,
		onSubmit: (values) => {
			dispatch(UpdateCatalogAction(values));
		},
	});
	useEffect(() => {
		catalog && formik.setValues(catalog);
	}, [catalog]);
	useEffect(() => {
			dispatch(GetAllProducts())
	}, [])

	return (
		<>
			<CardCustom
				title={"Dettaglio Catalogo " + catalog.name}
				rightTemplate={
					<BackBtn
						label="Torna alla lista cataloghi"
						clickHandler={() => changeRoute(routeName.catalog)}
					/>
				}>
				<Form onSubmit={formik.handleSubmit}>
					<FormElements
						structure={formStructureCatalog}
						formik={formik}></FormElements>
					<Row className="justify-content-end">
						<Col xs="auto">
							<PrimaryButton type="submit">Aggiorna</PrimaryButton>
						</Col>
					</Row>
				</Form>
			</CardCustom>
			<CardCustom title={"Lista prodotti presenti in " + catalog.name}>
				<AddProductToCatalog />
				<SelectedProductsTable products={selectedProducts} />
			</CardCustom>
		</>
	);
}
