import React from "react";
import {Col, Row} from "react-bootstrap";
import SecondaryButton from "../buttons/secondary-button/SecondaryButton";
import PrimaryButton from "../buttons/primary-button/PrimaryButton";


export function RowSaveCancel({closeForm, primaryLabel = 'Salva', secondaryLabel = 'Annulla'}) {

  return (
      <Row className="justify-content-end mb-3">
          <Col xs="auto">
              <SecondaryButton type="button" onClick={() => closeForm()}>
                  {secondaryLabel}
              </SecondaryButton>
          </Col>
          <Col xs="auto">
              <PrimaryButton type="submit">{primaryLabel}</PrimaryButton>
          </Col>
      </Row>
  );
}
