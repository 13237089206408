import * as Yup from "yup";
export const columnsCatalog = [
    {
        dataField: "name",
        text: "Catalogo",
        classes: "text-left pr-0",
        headerClasses: "text-left pr-3",
        style: {
            minWidth: "100px",
        },
    }
];

export const validationSchemaGdaAddCatalog = Yup.object().shape({
    idSub: Yup.string().required('Selezionare un subcatalogo')
})
