import React, {useState} from "react";
import {ShowHideForm} from "../../../shared/ui/forms/ShowHideForm";
import {FormCustomerDetailCreateGda} from "./FormCustomerDetailCreateGda";
import {configCloseForm} from "../../../shared/ui/forms/dataForm";

export function CustomerDetailCreateGda() {
    const [config, setConfig]= useState();
  return (
      <ShowHideForm config={config} buttonLabel="Aggiungi gruppo di acquisto">
          <FormCustomerDetailCreateGda closeForm={() => setConfig(configCloseForm())} />
      </ShowHideForm>
  );
}
