import React, {useState} from "react";
import {ShowHideForm} from "../../../shared/ui/forms/ShowHideForm";
import {FormCreateLpSubValidity} from "./FormCreateLpSubValidity";
import {configCloseForm} from "../../../shared/ui/forms/dataForm";


export function CreateLpSubValidity() {
    const [config, setConfig]= useState();
  return (
      <ShowHideForm config={config} buttonLabel="Aggiungi Validità">
          <FormCreateLpSubValidity closeForm={() => setConfig(configCloseForm())}/>
      </ShowHideForm>
  );
}
