/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout"
import Login from "./Login";
import "../../../../_metronic/_assets/sass/pages/users/login-1.scss";
import moment from "moment";

export function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white" id="kt_login">
          <div className="login-aside order-2 order-lg-1 d-flex flex-column-fluid flex-lg-row-auto bgi-size-cover bgi-no-repeat p-7 p-lg-10">
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              <div className="d-flex flex-column-fluid flex-column flex-center mt-5 mt-lg-0">
                <img src={"https://aelindelsi.blob.core.windows.net/assets/img/Linde_RWD_Claim.png"} className="mb-15 text-center max-h-75px" alt="" />
                <Switch>
                  <ContentRoute path="/auth/login" component={Login} />
                  <Redirect from="/auth" exact={true} to="/auth/login" />
                  <Redirect to="/auth/login" />
                </Switch>
              </div>
              <div className="d-flex flex-column-auto justify-content-between mt-15">
                <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">© {moment().format("YYYY")} Linde</div>
                <div className="d-flex order-1 order-sm-2 my-2">
                  <Link to="/terms" className="text-muted text-hover-primary">
                    Privacy
                  </Link>
                  <Link to="/terms" className="text-muted text-hover-primary ml-4">
                    Legal
                  </Link>
                  <Link to="/terms" className="text-muted text-hover-primary ml-4">
                    Contact
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="order-1 order-lg-2 flex-column-auto flex-lg-row-fluid d-flex flex-column p-7" style={{ backgroundImage: `url(https://aelindelsi.blob.core.windows.net/assets/img/1_dark.jpg)` }}>
            <div className="d-flex flex-column-fluid flex-lg-center">
              <div className="d-flex flex-column justify-content-center">
                <h3 className="display-3 font-weight-bold my-7 text-white">Linde Digital Care</h3>
                {/* <p className="font-weight-bold font-size-lg text-white opacity-80">The ultimate Bootstrap, Angular 8, React &amp; VueJS admin theme
    					<br />framework for next generation web apps.</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
