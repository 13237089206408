import React from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import {FormElements} from "../../../shared/ui/forms/FormElements";
import {formStructureLpSub, validationSchemaLpSub} from "../lpData";
import {Form} from "react-bootstrap";
import {CreateLpSubAction} from "../../../../redux/lp/lpActions";
import {RowSaveCancel} from "../../../shared/ui/forms/RowSaveCancel";


export function FormCreateLpSub({closeForm}) {
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchemaLpSub,
        onSubmit: values => {
            dispatch(CreateLpSubAction(values, closeForm))
        }
    });
  return (
      <Form onSubmit={formik.handleSubmit}>
          <FormElements structure={formStructureLpSub} formik={formik}></FormElements>
          <RowSaveCancel closeForm={closeForm} />
      </Form>
  );
}
