import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { CardCustom } from "../../../shared/ui/container/CardCustom";
import { Col, Form, Row } from "react-bootstrap";
import { FormElements } from "../../../shared/ui/forms/FormElements";
import PrimaryButton from "../../../shared/ui/buttons/primary-button/PrimaryButton";
import { formStructureCatalog, formStructureProduct } from "../lpData";
import { UpdateProductAction } from "../../../../redux/products/productsActions";
import { LpGenDetailTableProducts } from "../lpgen/LpGenDetailTableProducts";
import {
	updateValidityLpGenAction,
	updateValidityLpSubAction,
} from "../../../../redux/lp/lpActions";
import BackBtn from "../../../shared/ui/backBtn/BackBtn";
import { useRoute } from "../../../utils/useRoute";
import * as routeName from "../../../routes/routeNameAuthType";
import {permissionsData} from "../../../modules/Auth/_redux/permissionsData";
import IsPermission from "../../../shared/utils/IsPermission";

export function LpSubDetailValidityEdit({ element, idSub }) {
	const dispatch = useDispatch();
	const { changeRoute } = useRoute();

	const formik = useFormik({
		initialValues: {},
		onSubmit: (values) => {
			dispatch(updateValidityLpSubAction(idSub, values));
		},
	});
	useEffect(() => {
		element && formik.setValues(element);
	}, [element]);

	return (
		<CardCustom
			title={`Valido dal ${new Date(
				element?.beginValidity
			).toLocaleDateString()} al ${new Date(
				element?.endValidity
			).toLocaleDateString()}`}
			rightTemplate={
				<BackBtn
					label="Torna al listino prezzi"
					clickHandler={() => changeRoute(routeName.lp)}
				/>
			}>
			<Form onSubmit={formik.handleSubmit}>
				<FormElements
					structure={formStructureCatalog}
					formik={formik}></FormElements>
				{formik.values?.prices && <LpGenDetailTableProducts formik={formik} />}
				<IsPermission permissions={[permissionsData.lpEdit]} >
					<Row className="justify-content-end">
						<Col xs="auto">
							<PrimaryButton type="submit">Aggiorna</PrimaryButton>
						</Col>
					</Row>
				</IsPermission>

			</Form>
		</CardCustom>
	);
}
