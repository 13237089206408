import http from "../../app/utils/http";

export const LPGEN_URL = "lpgen";


export const getLpgenValidityList = () => http.get(`${LPGEN_URL}`)
export const createValidityLpgen = (data) => http.post(`${LPGEN_URL}`, {...data, name: 'test'})
export const getDetailValidityLpgen = (id) => http.get(`${LPGEN_URL}/${id}`)
export const updateDetailValidityLpgen = (data) => http.put(`${LPGEN_URL}/${data.id}`, data)
//export const createCatalog = (data) => http.post(`${LPGEN_URL}`, data)

export const LPSUB_URL = "lpsub";


export const getListLpSub = () => http.get(`${LPSUB_URL}`)
export const getDetailLpSub = (id) => http.get(`${LPSUB_URL}/${id}`)
export const createLpSub = (data) => http.post(`${LPSUB_URL}`, data)
export const createValidityLpSub = (id, data) => http.post(`${LPSUB_URL}/${id}/list`, {...data})
export const getValidityLpSubDetail = (idSub, id) => http.get(`${LPSUB_URL}/${idSub}/list/${id}`)
export const updateDetailValidityLpSub = (idSub, data) => http.put(`${LPSUB_URL}/${idSub}/list/${data.id}`, data)
