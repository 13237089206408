import http from "../../app/utils/http";

export const CATALOGS_URL = "sub-catalogs";


export const getCatalogs = () => http.get(`${CATALOGS_URL}`)
export const getCatalog = (id) => http.get(`${CATALOGS_URL}/${id}`)
export const createCatalog = (data) => http.post(`${CATALOGS_URL}`, data)
export const updateCatalogCall = (data) => http.put(`${CATALOGS_URL}/${data.id}`, data)
export const deleteCatalogCall = (id) => http.delete(`${CATALOGS_URL}/${id}`)
export const addProductToCatalogCall = (idCatalog, idProduct) => http.post(`${CATALOGS_URL}/${idCatalog}/products/${idProduct}`, {})
export const removeProductToCatalogCall = (idCatalog, idProduct) => http.delete(`${CATALOGS_URL}/${idCatalog}/products/${idProduct}`)
