import * as Yup from "yup";
import { DateColumnFormatter } from "../../component/CellRowTable/DateColumnFormatter";
import { TFColumnFormatter } from "../../component/CellRowTable/TFColumnFormatter";

export const columnsLpGen = [
    {
        dataField: "beginValidity",
        text: "Inizio Validità",
        formatter: DateColumnFormatter,
        formatExtraData: {
            titleCol: 'beginValidity',
        },
    },
    {
        dataField: "endValidity",
        text: "Fine Validità",
        formatter: DateColumnFormatter,
        formatExtraData: {
            titleCol: 'endValidity',
        },
    }
];


export const columnsLpProductsPrice = [
    {
        dataField: "title",
        text: "Nome"
    },
    {
        dataField: "descriptionSummary",
        text: "Descrizione"
    }
];

export const formStructureCatalog = [
    {
        name: 'beginValidity',
        label: 'Inizio Validità',
        type: 'input',
        dataElement: {
            type: 'date'
        }
    },
    {
        name: 'endValidity',
        label: 'Fine Validità',
        type: 'input',
        dataElement: {
            type: 'date'
        }
    }
].map(ele => ({...ele, col: {sm:"6", lg:"3"}}))

export const validationSchemaValidity = Yup.object().shape({
    beginValidity: Yup.string().required('Il campo è obbligatorio'),
    endValidity: Yup.string().required('Il campo è obbligatorio')
});


export const formStructureLpSub = [
    {
        name: 'name',
        label: 'Nome',
        type: 'input'
    },
    {
        name: 'active',
        label: 'Attivo',
        type: 'radio',
        format: 'boolean',
        options: [
            {
                label: 'Si',
                value: true
            },
            {
                label: 'No',
                value: false
            }
        ]
    }
].map(ele => ({...ele, col: {sm:"6", lg:"3"}}))

export const validationSchemaLpSub = Yup.object().shape({
    name: Yup.string().required('Il nome è obbligatorio'),
    active: Yup.string().required('Il campo è obbligatorio')
});

export const columnsLpSub = [
    {
        dataField: "name",
        text: "Nome"
    },
    {
        dataField: "active",
        text: "Attivo",
        formatter: TFColumnFormatter,
		formatExtraData: {
			labelTrue: "Attivo",
			labelFalse: "Non attivo",
			colName: "active",
		},
    }
];
