import React from "react";
import BootstrapTable from "react-bootstrap-table-next";

import { useRoute } from "../../../../utils/useRoute";
import {
	NoRecordsFoundMessage,
	PleaseWaitMessage,
} from "../../../../../_metronic/_helpers";
import { AddCatalogToGda } from "./AddCatalogToGda";
import { DeleteColumnFormatter } from "../../../../component/CellRowTable/DeleteColumnFormatter";
import { columnsCatalog } from "./gdaCatalogTableData";

export function GdaCatalogsTable({
	catalogos,
	selectedCatalogos = [],
	addCatalog,
	removeCatalog,
}) {
	const { changeRoute } = useRoute();
	const getSelectRow = (props) => {
		return {
			mode: "checkbox",
			clickToSelect: false,
			hideSelectAll: true,
			hideSelectColumn: true,
		};
	};

	const dataTable = [
		...columnsCatalog,
		{
			dataField: "delete",
			text: "",
			formatter: DeleteColumnFormatter,
			formatExtraData: {
				deleteRow: removeCatalog,
			},
			classes: "",
			style: {
				maxWidth: "100px",
			},
		},
	];

	return (
		<>
			<AddCatalogToGda catalogos={catalogos} addCatalog={addCatalog} />
			<BootstrapTable
				wrapperClasses="table-responsive"
				bordered={false}
				classes="table table-head-custom table-vertical-center overflow-hidden"
				bootstrap4
				remote
				hover={false}
				keyField="id"
				data={selectedCatalogos}
				columns={dataTable}
				selectRow={getSelectRow({
					selectedCatalogos,
				})}>
				<PleaseWaitMessage entities={selectedCatalogos} />
				<NoRecordsFoundMessage entities={selectedCatalogos} />
			</BootstrapTable>
		</>
	);
}
