import {
	sortCaret,
	headerSortingClasses,
} from "../../../../_metronic/_helpers";
import * as Yup from "yup";
import { TFColumnFormatter } from "../../../component/CellRowTable/TFColumnFormatter";
export const columnsCustomer = [
	{
		dataField: "erp",
		text: "Codice ERP",
		sort: false,
		sortCaret: sortCaret,
		headerSortingClasses,
	},
	{
		dataField: "name",
		text: "Nome",
		sort: false,
		sortCaret: sortCaret,
		headerSortingClasses,
	},
	{
		dataField: "type",
		text: "Tipologia",
		sort: false,
	},
	{
		dataField: "description",
		text: "Descrizione",
		sort: false,
		sortCaret: sortCaret,
		headerSortingClasses,
	},
	{
		dataField: "state",
		text: "Stato",
		formatter: TFColumnFormatter,
		formatExtraData: {
			labelTrue: "Attivo",
			labelFalse: "Non attivo",
			colName: "state",
		},
		sort: false,
		sortCaret: sortCaret,
		headerSortingClasses,
	},
	{
		dataField: "note",
		text: "Note",
		sort: false,
	},
];
export const formStructureCustomer = [
	{
		name: "name",
		label: "Nome",
		type: "input",
		col: { sm: "6", lg: "3" },
	},
	{
		name: "erp",
		label: "Codice ERP",
		type: "input",
		col: { sm: "6", lg: "3" },
	},
	{
		name: "state",
		label: "Stato",
		type: "radio",
		format: "boolean",
		options: [
			{
				label: "Attivo",
				value: true,
			},
			{
				label: "Disattivo",
				value: false,
			},
		],
		col: { sm: "6", lg: "3" },
	}
];
export const formStructureCustomerNote = [
	{
		name: "description",
		label: "Breve Descrizione",
		type: "textarea",
		col: { sm: "12", lg: "6" },
	},
	{
		name: "note",
		label: "Note",
		type: "textarea",
		col: { sm: "12", lg: "6" },
	},
];

export const validationSchemaCustomer = Yup.object().shape({
	name: Yup.string().required("Il nome è obbligatorio"),
	erp: Yup.string().required("ERP obbligatorio"),
	type: Yup.string().required("Il tipo è obbligatorio"),
	state: Yup.string().required("Stato obbligatorio"),
});

export const validationSchemaGda = Yup.object().shape({
	name: Yup.string().required("Il nome è obbligatorio"),
	state: Yup.string().required("Stato obbligatorio"),
	description: Yup.string().required("La descrizione è obbligatoria"),
	showPrice: Yup.string().required("Dato obbligatorio"),
	authRdo: Yup.string().required("Dato obbligatorio"),
});
