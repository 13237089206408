import React, {useEffect} from "react";
import { useParams } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {GetAllProducts} from "../../../../redux/products/productsActions";
import { GetDetailValidityLpSubAction} from "../../../../redux/lp/lpActions";
import {selectLpSubWithListProducts} from "../../../../redux/lp/lpGenSelector";
import {LpSubDetailValidityEdit} from "./LpSubDetailValidityEdit";

export function LpSubDetailValidityPage({ history }) {
    const {idSub, id} = useParams();
    const dispatch = useDispatch();
    const list = useSelector(selectLpSubWithListProducts)


    useEffect(() => {
        dispatch(GetDetailValidityLpSubAction(idSub, id));
        dispatch(GetAllProducts());
    }, [id])



    return (
        <div className="row">
            <div className="col-12">
                {list && (
                    <div>
                        <LpSubDetailValidityEdit element={list} idSub={idSub}/>
                    </div>
                )}
            </div>
        </div>
    );
}
