import {createSelector, createSlice} from "@reduxjs/toolkit";
import {selectListCatalogs} from "../catalogs/catalogsSlice";

const initialCustomersState = {
  selectedGda: undefined,
  entities: [],
  addableUsers: [],
  roles: []
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const gdaSlice = createSlice({
  name: "gda",
  initialState: initialCustomersState,
  reducers: {
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    // listGda
    gdaFetched: (state, action) => {
      state.entities = action.payload;
    },
    addGda: (state, action) => {
      state.entities = [...state.entities, action.payload];
    },
    removeUserToSelectedGda:  (state, action) => {
      state.selectedGda = {...state.selectedGda, users: state.selectedGda.users.filter(ele => ele.id  !== action.payload.id)};
      state.addableUsers = [...state.addableUsers, action.payload];
    },
    addUserToSelectedGda:  (state, action) => {
      state.selectedGda = {...state.selectedGda, users: [...state.selectedGda.users, action.payload]};
      state.addableUsers = state.addableUsers.filter(ele => ele.id !== action.payload.id);
    },
    addCatalogToSelectedGda:  (state, action) => {
      state.selectedGda = {...state.selectedGda, gda: {...state.selectedGda.gda, subCatalogos: [...(state.selectedGda.gda.subCatalogos || []), action.payload]}};
    },
    removeCatalogToSelectedGda:  (state, action) => {
      state.selectedGda = {...state.selectedGda, gda: {...state.selectedGda.gda, subCatalogos: (state.selectedGda.gda.subCatalogos || []).filter(ele => ele.id  !== action.payload)}};
    },
    setSelectedGda: (state, action) => {
      state.selectedGda = action.payload;
    },
    updateSelectedGda: (state, action) => {
      state.selectedGda = {...state.selectedGda, gda: action.payload};
    },
    resetSelectedGda: (state) => {
      state.selectedGda = undefined;
    },
    setGdaAddableUsers: (state, action) => {
      state.addableUsers = action.payload;
    },
    resetGdaAddableUsers: (state) => {
      state.addableUsers = [];
    },
    // createCustomer
    gdaCreated: (state, action) => {
      state.entities.push(action.payload.customer);
    },
    // updateCustomer
    gdaUpdated: (state, action) => {
      state.entities = state.entities.map(entity => entity.id === action.payload.gda.id ? action.payload.gda : entity);
    },
    // deleteCustomer
    gdaDeleted: (state, action) => {
      state.entities = state.entities.filter(el => el.id !== action.payload);
    }
  }
});

export const {gdaFetched, updateSelectedGda, setSelectedGda, addCatalogToSelectedGda, removeCatalogToSelectedGda, resetSelectedGda, setRoles, resetGdaAddableUsers, addGda, gdaDeleted, setGdaAddableUsers, removeUserToSelectedGda, addUserToSelectedGda} = gdaSlice.actions;

export const selectSelectedGda = (state) => state.gda.selectedGda;
export const selectRoles = (state) => state.gda.roles;
export const selectListGda = (state) => state.gda.entities;
export const selectAddableUsers = (state) => state.gda.addableUsers;
export const selectAddableCatalogs = createSelector(selectSelectedGda, selectListCatalogs, (gda, catalogos = []) => catalogos.filter(ele => !(gda?.gda?.subCatalogos || []).find(el => el.id === ele.id)) );
