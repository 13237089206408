// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
	PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
	getHandlerTableChange,
	NoRecordsFoundMessage,
	PleaseWaitMessage,
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../CustomersUIHelpers";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { useCustomersUIContext } from "../CustomersUIContext";
import { useRoute } from "../../../../utils/useRoute";
import * as routeName from "../../../../routes/routeNameAuthType";
import { columnsCustomer } from "../customerData";
import { DeleteColumnFormatter } from "../../../../component/CellRowTable/DeleteColumnFormatter";
import {deleteCustomer} from "../../../../../redux/customers/customersActions";

export function CustomersTable() {
	// Customers UI Context
	const customersUIContext = useCustomersUIContext();
	const customersUIProps = useMemo(() => {
		return {
			ids: customersUIContext.ids,
			setIds: customersUIContext.setIds,
			queryParams: customersUIContext.queryParams,
			setQueryParams: customersUIContext.setQueryParams,
			openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
			openDeleteCustomerDialog: customersUIContext.openDeleteCustomerDialog,
		};
	}, [customersUIContext]);
	console.log(customersUIContext);
	// Getting curret state of customers list from store (Redux)
	const { currentState } = useSelector(
		(state) => ({ currentState: state.customers }),
		shallowEqual
	);
	const { totalCount, entities, listLoading } = currentState;
	const pageSize = customersUIContext.queryParams.pageSize || 10;
	const filterEntities =
		entities?.filter(
			(ele, index) =>
				index >= pageSize * (customersUIContext.queryParams.pageNumber - 1) &&
				index < pageSize * customersUIContext.queryParams.pageNumber
		) || [];
	// Customers Redux state
	const dispatch = useDispatch();
	useEffect(() => {
		// clear selections list
		// customersUIProps.setIds([]);
		// server call by queryParams
		// dispatch(actions.fetchCustomers(customersUIProps.queryParams));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customersUIProps.queryParams]);
	const { changeRoute } = useRoute();
	const getSelectRow = (props) => {
		const { entities, ids, setIds } = props;
		return {
			mode: "checkbox",
			clickToSelect: true,
			hideSelectAll: true,
			hideSelectColumn: true,
			onSelect: (row, isSelect, rowIndex, e) => {
				changeRoute(routeName.clientDetail, { id: row.id });
			},
		};
	};
	
	// Table pagination properties
	const paginationOptions = {
		custom: true,
		totalSize: totalCount,
		sizePerPageList: uiHelpers.sizePerPageList,
		sizePerPage: customersUIProps.queryParams.pageSize,
		page: customersUIProps.queryParams.pageNumber,
	};
	const removeUser = (row) => {
		dispatch(deleteCustomer(row.id))
	};
	const dataTable = [
		...columnsCustomer,
		{
			dataField: "delete",
			text: "",
			formatter: DeleteColumnFormatter,
			formatExtraData: {
				deleteRow: removeUser,
			},
			classes: "",
			style: {
				maxWidth: "100px",
			},
		},
	];

	return (
		<>
			<PaginationProvider pagination={paginationFactory(paginationOptions)} >
				{({ paginationProps, paginationTableProps }) => {
					return (
						<Pagination
							isLoading={listLoading}
							paginationProps={paginationProps}>
							<BootstrapTable
								wrapperClasses="table-responsive"
								bordered={false}
								classes="table table-head-custom table-vertical-center overflow-hidden"
								bootstrap4
								remote
								hover={true}
								keyField="id"
								data={filterEntities}
								columns={dataTable}
								defaultSorted={uiHelpers.defaultSorted}
								onTableChange={getHandlerTableChange(
									customersUIProps.setQueryParams
								)}
								selectRow={getSelectRow({
									entities,
									ids: customersUIProps.ids,
									setIds: customersUIProps.setIds,
								})}
								{...paginationTableProps}>
								<PleaseWaitMessage entities={entities} />
								<NoRecordsFoundMessage entities={entities} />
							</BootstrapTable>
						</Pagination>
					);
				}}
			</PaginationProvider>
		</>
	);
}
