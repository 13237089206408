import React, {useState} from "react";
import {ShowHideForm} from "../../../../shared/ui/forms/ShowHideForm";
import {configCloseForm} from "../../../../shared/ui/forms/dataForm";
import {FormAddUserToGda} from "./FormAddUserToGda";



export function AddUserToGda({addableUsers = [], roles = [], addUser}) {
    const [config, setConfig]= useState();
  return (
      <ShowHideForm config={config} buttonLabel="Aggiungi Utente">
          <FormAddUserToGda addableUsers={addableUsers} addUser={addUser} roles={roles} closeForm={() => setConfig(configCloseForm())} />
      </ShowHideForm>
  );
}
