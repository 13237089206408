import {formStructureGda, formStructureGdaFirst, formStructureGdaSecond} from "./customerData";

export function UseCustomerDetailCreateGda(templatesRdo = []) {
	const formStructure = [
		...formStructureGdaFirst,
		{
			name: "templateRdo",
			label: "Template Ordine",
			type: "select",
			options: templatesRdo.map(({ id: value, label }) => ({ value, label })),
			placeholder: "No",
			col: { sm: "6", lg: "3" },
		},
		...formStructureGdaSecond
	];
	return {
		formStructure,
	};
}
