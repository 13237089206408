/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {InputCustom} from "../../../shared/ui/forms/input/InputCustom";

export function ActionsBeginValidityFormatter(
  cellContent,
  row,
  rowIndex,
  { formik }
) {
    const data = {
        name: 'prices['+ rowIndex +'].beginValidity',
        dataElement: {
            type: 'date'
        }
    }
  return (
    <>
        <span>
            <InputCustom ele={data} val={formik.values.prices[rowIndex].beginValidity} changeValue={(value) => formik.setFieldValue(data.name, value)}/>
        </span>

    </>
  );
}
