import React, {useState} from "react";
import {configCloseForm} from "../../shared/ui/forms/dataForm";
import {ShowHideForm} from "../../shared/ui/forms/ShowHideForm";
import {FormAddProductToCatalog} from "./FormAddProductToCatalog";

export function AddProductToCatalog() {
    const [config, setConfig]= useState();
  return (
      <ShowHideForm config={config} buttonLabel="Aggiungi Prodotto">
          <FormAddProductToCatalog closeForm={() => setConfig(configCloseForm())} />
      </ShowHideForm>
  );
}
