import React, {useEffect} from "react";
import {Card, CardBody, CardHeader} from "../../../../_metronic/_partials/controls";
import {CustomersTable} from "./components/CustomersTable";
import {CustomersUIProvider} from "./CustomersUIContext";
import {useDispatch} from "react-redux";
import {fetchCustomers} from "../../../../redux/customers/customersActions";
import {CustomerCreate} from "./CustomerCreate";

export function CustomersPage({ history }) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCustomers())
    }, [])
  return (
      <div className="row">
        <div className="col-12">
          <Card>
            <CardHeader title="Clienti"/>
            <CardBody>
                <CustomerCreate />
                <CustomersUIProvider>
                    <CustomersTable/>
                </CustomersUIProvider>
            </CardBody>
          </Card>
        </div>
      </div>
  );
}
