/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import routes from "../../../../../app/routes/auth";
import {useRoute} from "../../../../../app/utils/useRoute";
import {usePermissions} from "../../../../../app/shared/utils/usePemissions";

export function HeaderMenu({ layoutProps }) {
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }
    const {issetPermission} = usePermissions();
    const listRoutes = (routes.list?.filter((ele) => ele.navTitle) || [])
        .filter((r) => r.permissions && r.permissions.length ? issetPermission(r.permissions) : true)
    const {changeRoute} = useRoute();
    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {listRoutes.map(ele => (
                <li
                    key={ele.path}
                    className={`menu-item menu-item-rel ${getMenuItemActive(ele.path)}`}
                    aria-haspopup="true">
                    <a className="menu-link"
                       onClick={(event) => {
                           event.preventDefault();
                           changeRoute(ele.name)
                       }} href={ele.path}>
                        <span className="menu-text">{ele.navTitle.label}</span>
                    </a>
                </li>
            ))}
        </ul>
    </div>;
}
