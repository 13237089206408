import { ActionsColumnFormatter } from "./ActionsColumnFormatter";
import * as Yup from "yup";
export const columnsGda = [
    {
        dataField: "name",
        text: "Nome",
        classes: "text-left pr-0",
        headerClasses: "text-left pr-3",
        style: {
            minWidth: "100px",
        },
    },
    {
        dataField: "surname",
        text: "Cognome",
        classes: "text-left pr-0",
        headerClasses: "text-left pr-3",
        style: {
            minWidth: "100px",
        },
    },
    {
        dataField: "email",
        text: "Email",
        classes: "text-left pr-0",
        headerClasses: "text-left pr-3",
        style: {
            minWidth: "100px",
        },
    },
    {
        dataField: "action",
        text: "Ruoli",
        formatter: ActionsColumnFormatter,
        classes: "text-left pr-0",
        headerClasses: "text-left pr-3",
        style: {
            minWidth: "100px",
        },
    },
];

export const validationSchemaGdaAddUser = Yup.object().shape({
    user: Yup.string().required('Utente Obbligatorio'),
    roles: Yup.array().required('Selezionare almeno un ruolo')
})
