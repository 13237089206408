import { createUserManager } from 'redux-oidc';

const settings = {
    authority: "https://identity.lindemedicale.net/",
    // authority: "https://localhost:44315",
    // authority: "https://linde-digital-care-dev.azurewebsites.net/",
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
    response_type: "code",
    scope: "openid profile email sgboclaims sgbo_api roles", // offline_access",
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/logout`,
    silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew`,
    automaticSilentRenew: true,
};

const userManager = createUserManager(settings);

export default userManager;
