import React, {useEffect} from "react";
import {FormElements} from "../../../shared/ui/forms/FormElements";
import {formStructureCustomer, validationSchemaCustomer} from "./customerData";
import {Col, Form, Row} from "react-bootstrap";
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import SecondaryButton from "../../../shared/ui/buttons/secondary-button/SecondaryButton";
import PrimaryButton from "../../../shared/ui/buttons/primary-button/PrimaryButton";
import {createCustomerAction, getTypesCustomerAction} from "../../../../redux/customers/customersActions";
import {UseCreateCustomer} from "./UseCreateCustomer";
import {selectCustomerTypes} from "../../../../redux/customers/customersSlice";

export function FormCustomerCreate({closeForm}) {
    const dispatch = useDispatch();
    const types = useSelector(selectCustomerTypes)
    useEffect(() => {
        dispatch(getTypesCustomerAction())
    }, [])
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchemaCustomer,
        onSubmit: values => {
            dispatch(createCustomerAction(values, () => closeForm()))
        }
    });
    const {formStructure} = UseCreateCustomer(types)
  return (
         <Form onSubmit={formik.handleSubmit}>
              <FormElements structure={formStructure} formik={formik}></FormElements>
              <Row className="justify-content-end mb-3">
                  <Col xs="auto">
                      <SecondaryButton type="button" onClick={() => closeForm()}>
                          Annulla
                      </SecondaryButton>
                  </Col>
                  <Col xs="auto">
                      <PrimaryButton type="submit">Salva</PrimaryButton>
                  </Col>
              </Row>
          </Form>
  );
}
