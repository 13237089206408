import React from "react";
import BootstrapTable from "react-bootstrap-table-next";

import {NoRecordsFoundMessage, PleaseWaitMessage} from "../../../_metronic/_helpers";
import {columnsProducts} from "../products/productsData";
import {useDispatch} from "react-redux";
import {RemoveProductToCatalogAction} from "../../../redux/catalogs/catalogsActions";
import {DeleteColumnFormatter} from "../../component/CellRowTable/DeleteColumnFormatter";

export function SelectedProductsTable({products}) {
    const dispatch = useDispatch();
    const removeProduct = (row) => {
        dispatch(RemoveProductToCatalogAction(row.id))
    }
  const getSelectRow = (props) => {
    return {
      mode: "checkbox",
      clickToSelect: true,
      hideSelectAll: true,
      hideSelectColumn: true,
      onSelect: (row, isSelect, rowIndex, e) => {
      },
    };
  }
  const dataTable = [
      ...columnsProducts,
      {
          dataField: "delete",
          text: "",
          formatter: DeleteColumnFormatter,
          formatExtraData: {
              deleteRow: removeProduct,
          },
          classes: "",
          style: {
              maxWidth: "100px",
          },
      }
  ];

  return (
    <>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                hover={false}
                keyField="id"
                data={products}
                columns={dataTable}
                selectRow={getSelectRow({
                  products
                })}
              >
                <PleaseWaitMessage entities={products} />
                <NoRecordsFoundMessage entities={products} />
              </BootstrapTable>
    </>
  );
}
