import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage/session';
import {createSelector} from "@reduxjs/toolkit";
import {permissionsData} from "./permissionsData";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined
};
const scopeType = {
  canmanageall: 'canmanageall',
  canmanage: 'canmanage',
  canview: 'canview'
}
const trasformScopeToArray = (scopes) => typeof scopes === "string"? [scopes] : scopes
const calcPermissionByScope = (category, type) => {
  if(type.includes(scopeType.canmanageall)){
    return Object.keys(permissionsData).map(ele => permissionsData[ele]);
  } else if(type.includes(scopeType.canmanage)){
    return [category+'Edit', category+'Read']
  }else if(type.includes(scopeType.canview)){
    return [category+'Read']
  } else {
    return []
  }
}
const calcScope = (scopes = []) => {
  const scopesClear = trasformScopeToArray(scopes);
  return scopesClear.reduce((acc, ele) => {
    const [category, type] = ele.split(':');
    return [...acc, ...calcPermissionByScope(category, type)]
  }, []).reduce((acc, ele) => {
    return [...acc, ...(acc.includes(ele) ? [] : [ele])]
  }, [])
}

export const reducer = persistReducer(
  { storage, key: "auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { data } = action.payload;

        let user = {
          username: data.entities.profile.preferred_username,
          email: data.entities.profile.preferred_username,
          associatedCustomersId: data.entities?.profile?.associatedCustomersId?.split(',') || [],
          resource_scope: calcScope(data.entities.profile?.resource_scope),
          fullname: data.entities.profile.name,
          firstname: data.entities.profile.given_name,
          lastname: data.entities.profile.family_name
        }
        localStorage.setItem("customerId", user.associatedCustomersId[0]);

        return { authToken: data.entities.access_token, user };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        localStorage.removeItem("customerId");
        return initialAuthState;
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: data => (
    {
      type: actionTypes.Login,
      payload: { data }
    }
  ),
  logout: () => ({ type: actionTypes.Logout }),
};

export const selectUser = (state) => state.auth.user;
export const selectPermissions = createSelector(selectUser, (user) => user.resource_scope)
