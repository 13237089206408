import {createSelector} from "@reduxjs/toolkit";
import {selectSelectedLpGenValidity, selectSelectedLpSubValidity} from "./lpSlice";
import {selectListProducts} from "../products/productsSlice";
import {copyObject} from "../../app/utils/objUtils";



export const selectLpGenWithListProducts = createSelector(selectListProducts, selectSelectedLpGenValidity, (products, validity = {}) => {
    return {...validity, prices: products.map(ele => {
        const product = copyObject({...ele, productId: ele.id});
        delete product.id;
        const price = (validity?.prices || []).find(el => el.productId === product.productId) || {}
        return ({...product, ...price })
        })}
})
export const selectLpSubWithListProducts = createSelector(selectListProducts, selectSelectedLpSubValidity, (products, validity = {}) => {
    return {...validity, prices: products.map(ele => {
        const product = copyObject({...ele, productId: ele.id});
        delete product.id;
        const price = (validity?.prices || []).find(el => el.productId === product.productId) || {}
        return ({...product, ...price })
        })}
})
