import React from "react";
import {FormGroup, FormLabel} from "react-bootstrap";

export function FormGroupCustom({ label, children }) {
	return (
		<FormGroup>
			<FormLabel>{label}</FormLabel>
			{children}
		</FormGroup>
	);
}
