import {createSelector, createSlice} from "@reduxjs/toolkit";
import {selectListProducts, selectSelectedProduct} from "../products/productsSlice";

const initialCustomersState = {
  entities: [],
  selectedCatalog: undefined,
  selectedProducts: []
};


export const catalogsSlice = createSlice({
  name: "catalogs",
  initialState: initialCustomersState,
  reducers: {
    // listProducts
    setCatalogs: (state, action) => {
      state.entities = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setSelectedProducts: (state, action) => {
      state.selectedProducts = action.payload;
    },
    addSelectedProducts: (state, action) => {
      state.selectedProducts = [...state.selectedProducts, action.payload];
    },
    removeSelectedProducts: (state, action) => {
      state.selectedProducts = state.selectedProducts.filter(prod => prod.id !== action.payload.id);
    },
    addCatalog: (state, action) => {
      state.entities = [...state.entities, action.payload];
    },
    setSelectedCatalog: (state, action) => {
      state.selectedCatalog = action.payload;
    },
    updateCatalog: (state, action) => {
      state.selectedCatalog = action.payload;
      state.entities = state.entities.map(ele => ele.id === action.payload.id ? action.payload : ele)
    },
    removeCatalog: (state, action) => {
      state.entities = state.entities.filter(ele => ele.id !== action.payload)
    }
  }
});

export const {updateCatalog, removeSelectedProducts, setSelectedCatalog, addCatalog, addSelectedProducts, removeCatalog, setCatalogs, setSelectedProducts} = catalogsSlice.actions;

export const selectListCatalogs = (state) => state.catalogs.entities;
export const selectSelectedCatalog = (state) => state.catalogs.selectedCatalog;
export const selectSelectedProducts = (state) => state.catalogs.selectedProducts;
export const selectProductToAdd = createSelector(selectListProducts, selectSelectedProducts, (products, selectedProducts) => products.filter(prod => !selectedProducts.find(ele => ele.id === prod.id)))
export const selectCatalogToAdd = createSelector(selectListCatalogs, selectSelectedProduct, (catalogs, product) => catalogs.filter(cat => !product?.cSubs?.find(ele => ele.id === cat.id)))
