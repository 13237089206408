import React from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import {FormElements} from "../../../shared/ui/forms/FormElements";
import {formStructureCatalog, validationSchemaValidity} from "../lpData";
import {Form} from "react-bootstrap";
import {CreateLpSubValidityAction} from "../../../../redux/lp/lpActions";
import {RowSaveCancel} from "../../../shared/ui/forms/RowSaveCancel";
import {getEndOfDay, getStartOfDay} from "../../../utils/dateUtils";


export function FormCreateLpSubValidity({closeForm}) {
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchemaValidity,
        onSubmit: values => {
            const data = {
                beginValidity: getStartOfDay(values.beginValidity).toISOString(),
                endValidity: getEndOfDay(values.endValidity).toISOString()
            };
            dispatch(CreateLpSubValidityAction(data, closeForm))
        }
    });
  return (
      <Form onSubmit={formik.handleSubmit}>
          <FormElements structure={formStructureCatalog} formik={formik}></FormElements>
          <RowSaveCancel closeForm={closeForm} />
      </Form>
  );
}
