import * as requestFromServer from "./lpCrud";
import { copyObject, objDeleteProperties } from "../../app/utils/objUtils";
import {
	addLpGenValidity,
	addLpSub,
	addValiditySelectedLpSub,
	setLpGenValidity,
	setLpSub,
	setSelectedLpGenValidity,
	setSelectedLpSub,
	setSelectedLpSubValidity,
} from "./lpSlice";
import {
	setErrorTypeAction,
	setSuccessErrorAction,
} from "../errors/errorsActions";
import { resetErrors } from "../errors/errorsSlice";
import {getEndOfDay, getStartOfDay} from "../../app/utils/dateUtils";

//LPGEN
export const GetLpGenValidityListAction = () => (dispatch) => {
	return requestFromServer
		.getLpgenValidityList()
		.then((response) => {
			dispatch(setLpGenValidity(response));
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction("Errore nel caricamento della lista validità LpGen")
			);
		});
};

export const GetDetailValidityLpGenAction = (id) => (dispatch) => {
	return requestFromServer
		.getDetailValidityLpgen(id)
		.then((response) => {
			dispatch(
				setSelectedLpGenValidity({
					...response,
					prices:
						response.prices?.map((ele) => ({
							...ele,
							beginValidity: ele.beginValidityDiscount,
							endValidity: ele.endValidityDiscount,
						})) || [],
				})
			);
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction(
					"Errore nel caricamento dettaglio della lista validità LpGen"
				)
			);
		});
};

export const CreateValidityLpGenAction = (data, callback) => (dispatch) => {
	dispatch(resetErrors());
	return requestFromServer
		.createValidityLpgen(data)
		.then((response) => {
			dispatch(addLpGenValidity(response));
			callback && callback();
			dispatch(
				setSuccessErrorAction("Lista validità LpGen creata con successo")
			);
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction("Errore nel salvataggio della lista validità LpGen")
			);
		});
};

export const updateValidityLpGenAction = (data, callback) => (dispatch) => {
	dispatch(resetErrors());
	const copy = copyObject(data);
	copy.prices = copy.prices
		.filter((ele) => ele.amount)
		.map(({ amount, discount, beginValidity, endValidity, productId }) => ({
			amount,
			discount,
			beginValidity: beginValidity ? getStartOfDay(beginValidity).toISOString() : undefined,
			endValidity: endValidity? getEndOfDay(endValidity).toISOString() : undefined,
			productId,
		}));
	return requestFromServer
		.updateDetailValidityLpgen(copy)
		.then((response) => {
			//dispatch(addLpGenValidity(response))
			dispatch(
				setSuccessErrorAction("Lista validità LpGen modifcata con successo")
			);
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction("Errore nella modifica della lista validità LpGen")
			);
		});
};

// export const UpdateCatalogAction = (data, callback) => dispatch => {
//     const copyData = objDeleteProperties(data, ['infoUpdate'])
//   return requestFromServer
//     .updateCatalogCall(copyData)
//     .then(response => {
//          dispatch(updateCatalog(response))
//         callback && callback()
//     })
//     .catch(error => {
//       //gestione errori
//     });
// };

//LPSUB
export const GetLpSubList = () => (dispatch) => {
	return requestFromServer
		.getListLpSub()
		.then((response) => {
			dispatch(setLpSub(response));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction("Errore nel caricamento della lista listino prezzi associato"));
		});
};

export const GetLpSubDetailAction = (id) => (dispatch) => {
	return requestFromServer
		.getDetailLpSub(id)
		.then((response) => {
			dispatch(setSelectedLpSub(response));
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction("Errore nel caricamento dettaglio della lista listino prezzi associato")
			);
		});
};

export const CreateLpSubAction = (data, callback) => (dispatch) => {
	dispatch(resetErrors());
	return requestFromServer
		.createLpSub(data)
		.then((response) => {
			dispatch(addLpSub(response));
			callback && callback();
			dispatch(
				setSuccessErrorAction(
					"Salvataggio della lista listino prezzi associato avvenuta con successo"
				)
			);
		})
		.catch((error) => {
			dispatch(setErrorTypeAction("Errore nel salvataggio della lista listino prezzi associato"));
		});
};

export const CreateLpSubValidityAction = (data, callback) => (
	dispatch,
	getState
) => {
	dispatch(resetErrors());
	const id = getState().lp.selectedLpSub.id;
	return requestFromServer
		.createValidityLpSub(id, data)
		.then((response) => {
			dispatch(addValiditySelectedLpSub(response));
			callback && callback();
			dispatch(
				setSuccessErrorAction("Lista validità listino prezzi associato creata con successo")
			);
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction("Errore nel salvataggio della lista validità listino prezzi associato")
			);
		});
};

export const GetDetailValidityLpSubAction = (idSub, id) => (dispatch) => {
	return requestFromServer
		.getValidityLpSubDetail(idSub, id)
		.then((response) => {
			dispatch(
				setSelectedLpSubValidity({
					...response,
					prices:
						response.prices || [],
				})
			);
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction(
					"Errore nel caricamento dettaglio della lista validità listino prezzi associato"
				)
			);
		});
};

export const updateValidityLpSubAction = (idSub, data, callback) => (
	dispatch
) => {
	dispatch(resetErrors());
	const copy = copyObject(data);
	copy.prices = copy.prices
		.filter((ele) => ele.amount)
		.map(({ amount, discount, beginValidity, endValidity, productId }) => ({
			amount,
			discount,
			beginValidity: beginValidity ? getStartOfDay(beginValidity).toISOString() : undefined,
			endValidity: endValidity? getEndOfDay(endValidity).toISOString() : undefined,
			productId,
		}));
	return requestFromServer
		.updateDetailValidityLpSub(idSub, copy)
		.then((response) => {
			//dispatch(addLpGenValidity(response))
			dispatch(
				setSuccessErrorAction("Lista validità listino prezzi associato modificata con successo")
			);
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction("Errore nella modifica della lista validità listino prezzi associato")
			);
		});
};
