/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import Icon from "../../shared/ui/icon/Icon";

export function DeleteColumnFormatter(
	cellContent,
	row,
	rowIndex,
	{ deleteRow }
) {
	return (
		<>
			<Icon
				className="justify-content-end"
				icon="trash"
				clickAction={(event) => {
					event.stopPropagation();
					deleteRow(row);
				}}></Icon>
		</>
	);
}
