import React from "react";
import { GdaCustomersUIProvider } from "./GdaCustomersUIContext";
import { GdaCustomersTable } from "./components/GdaCustomersTable";
import { CustomerDetailCreateGda } from "./CustomerDetailCreateGda";
import { CardCustom } from "../../../shared/ui/container/CardCustom";

export function CustomerDetailGda({ customer }) {
	return (
		<CardCustom title="Gruppi di acquisto">
			<div className="row">
				<div className="col-12">
					<CustomerDetailCreateGda />
					<GdaCustomersUIProvider>
						<GdaCustomersTable customer={customer} />
					</GdaCustomersUIProvider>
				</div>
			</div>
		</CardCustom>
	);
}
