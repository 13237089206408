import {
	headerSortingClasses,
	sortCaret,
} from "../../../../_metronic/_helpers";
import { TFColumnFormatter } from "../../../component/CellRowTable/TFColumnFormatter";

export const columnsCustomer = [
	{
		dataField: "name",
		text: "Nome",
		sort: false,
		sortCaret: sortCaret,
		headerSortingClasses,
	},
	{
		dataField: "description",
		text: "Descrizione",
		sort: false,
		sortCaret: sortCaret,
		headerSortingClasses,
	},
	{
		dataField: "state",
		text: "Stato",
		formatter: TFColumnFormatter,
		formatExtraData: {
			labelTrue: "Attivo",
			labelFalse: "Non attivo",
			colName: "state",
		},
		sort: false,
		sortCaret: sortCaret,
		headerSortingClasses,
	},
	{
		dataField: "showPrice",
		text: "Visualizzazione prezzo",
		formatter: TFColumnFormatter,
		formatExtraData: {
			labelTrue: "Si",
			labelFalse: "No",
			colName: "showPrice",
		},
		sort: false,
		sortCaret: sortCaret,
		headerSortingClasses,
	},
	{
		dataField: "authRdo",
		text: "Richiesta autorizzazione per acquisto prodotti",
		formatter: TFColumnFormatter,
		formatExtraData: {
			labelTrue: "Si",
			labelFalse: "No",
			colName: "authRdo",
		},
		sort: false,
		sortCaret: sortCaret,
		headerSortingClasses,
	},
	{
		dataField: "note",
		text: "Note",
		sort: false,
	},
];
