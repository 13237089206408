import React from "react";
import {FormControl} from "react-bootstrap";
import {MultiSelectForm} from "./MultiSelectForm";
import arrow from './img/arrow-down.png'
export function SelectForm({ ele, formik }) {
	const options = ele.placeholder? [{value: '-1', label: ele.placeholder}, ...ele.options] : ele.options;
	return (
		<>
			{ele.multi ? <MultiSelectForm ele={ele} formik={formik} />  : <FormControl
				custom
				as="select"
				style={{backgroundImage: `url(${arrow})`}}
				value={formik.values[ele.name] !== undefined && formik.values[ele.name] !== "-1" ? formik.values[ele.name] : "-1"}
				onChange={(event) =>
					formik.setFieldValue(
						ele.name,
						event.target.value !== "-1" ? (ele.format === 'number' ? +event.target.value : event.target.value) : undefined
					)
				}
				{...(ele?.dataElement || {})}>
				{options.map((opt, index) => (
					<option key={opt.value} value={opt.value}>
						{opt.label}
					</option>
				))}
			</FormControl>}
		</>

	);
}
