import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    selectCategories,
    selectTemplates,
    selectTemplatesRdo,
    selectTypes
} from "../../../redux/products/productsSlice";
import {
    CreateProductAction,
    GetListCategoriesAction,
    GetListTemplateAction, GetListTemplateRdoAction,
    GetListTypeAction
} from "../../../redux/products/productsActions";
import {useFormik} from "formik";
import {getBase64} from "../../utils/fileUtils";
import {UseCreateProduct} from "./UseCreateProduct";
import {FormElements} from "../../shared/ui/forms/FormElements";
import {Col, Form, Row} from "react-bootstrap";
import SecondaryButton from "../../shared/ui/buttons/secondary-button/SecondaryButton";
import PrimaryButton from "../../shared/ui/buttons/primary-button/PrimaryButton";
import {validationSchemaProduct} from "./productsData";


export function FormCreateProduct({closeForm}) {
    const dispatch = useDispatch();
    const types = useSelector(selectTypes);
    const categories = useSelector(selectCategories);
    const templates = useSelector(selectTemplates);
    const templatesRdo = useSelector(selectTemplatesRdo);
    useEffect(() => {
        dispatch(GetListTypeAction());
        dispatch(GetListTemplateAction());
        dispatch(GetListTemplateRdoAction());
        dispatch(GetListCategoriesAction());
    }, [])
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchemaProduct,
        onSubmit: values => {
            const callback = () => closeForm();
            if(values.img){
                getBase64(values.img.file).then(img => {
                    const copy = {...values, img}
                    dispatch(CreateProductAction(copy, callback))
                })
            }else{
                dispatch(CreateProductAction(values, callback))
            }
        }
    });
    const {formStructureProduct} = UseCreateProduct(types, categories, templates, templatesRdo);
  return (
          <Form onSubmit={formik.handleSubmit}>
              <FormElements structure={formStructureProduct} formik={formik}></FormElements>
              <Row className="justify-content-end mb-3">
                  <Col xs="auto">
                      <SecondaryButton type="button" onClick={() => closeForm()}>
                          Annulla
                      </SecondaryButton>
                  </Col>
                  <Col xs="auto">
                      <PrimaryButton type="submit">Salva</PrimaryButton>
                  </Col>
              </Row>
          </Form>
  );
}
