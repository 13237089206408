import { resetModal, setModal } from "../../app/shared/redux/modal/modalSlice";
import {
	setErrorTypeAction,
	setSuccessErrorAction,
} from "../errors/errorsActions";
import { resetErrors } from "../errors/errorsSlice";
import * as requestFromServer from "./gdaCrud";
import {
	gdaSlice,
	setSelectedGda,
	setGdaAddableUsers,
	addGda,
	removeUserToSelectedGda,
	addUserToSelectedGda,
	setRoles,
	gdaDeleted, addCatalogToSelectedGda, removeCatalogToSelectedGda, updateSelectedGda,
} from "./gdaSlice";

const { actions } = gdaSlice;

export const fetchAllAddableUserGda = (id, idGda) => (dispatch) => {
	return requestFromServer
		.getAllAddableUserGda(id, idGda)
		.then((response) => {
			dispatch(setGdaAddableUsers(response));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction("Errore nel caricamento dei clienti"));
		});
};

export const fetchGda = (customerId, id) => (dispatch) => {
	return requestFromServer
		.getGdaById(customerId, id)
		.then((response) => {
			dispatch(setSelectedGda(response));
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction("Errore nel caricamento del gruppo di acquisto")
			);
		});
};

export const deleteGdaAction = (customerId, id) => (dispatch) => {
	const buttonList = [
		{
			label: "Annulla",
			action: "cancelDelete",
			type: "secondary",
			dataType: {},
		},
		{
			label: "Conferma",
			action: "confirmDelete",
			type: "primary",
			dataType: {},
		},
	];
	const confirmDelete = () => {
		requestFromServer
			.deleteGda(customerId, id)
			.then((response) => {
				dispatch(gdaDeleted(id));
				dispatch(
					setSuccessErrorAction("Gruppo di acquisto eliminato con successo")
				);
			})
			.catch((error) => {
				dispatch(
					setErrorTypeAction(
						"Errore nella cancellazione del gruppo di acquisto"
					)
				);
			});
		dispatch(resetModal());
	};
	const cancelDelete = () => {
		dispatch(resetModal());
	};
	dispatch(
		setModal(
			buttonList,
			{
				title: "Cancella Gruppo di acquisto",
				body: `Sei sicuro di voler cancellare il gruppo di acquisto selezionato?`,
			},
			{
				cancelDelete,
				confirmDelete,
			}
		)
	);
};

export const createGda = (gda, callback) => (dispatch, getState) => {
	dispatch(resetErrors());
	const customerId = getState().customers.customerForEdit.id;
	return requestFromServer
		.createGda(customerId, gda)
		.then((response) => {
			dispatch(addGda(response));
			callback && callback();
			dispatch(setSuccessErrorAction("Gruppo di acquisto creato con successo"));
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction("Errore nel salvataggio del gruppo di acquisto")
			);
		});
};

export const addCatalogGda = (gda, idSub, callback) => (dispatch, getState) => {
	dispatch(resetErrors());
	const catalog = (getState().catalogs.entities || []).find(ele => ele.id === idSub);
	return requestFromServer
		.addCatalogToGda(gda, idSub)
		.then((res) => {
			dispatch(
				addCatalogToSelectedGda(catalog));
			callback && callback();
			dispatch(
				setSuccessErrorAction(
					"Catalogo associato con successo al gruppo di acquisto"
				)
			);
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction(
					"Errore nell'associazione del catalogo al gruppo di acquisto"
				)
			);
		});
};

export const removeCatalogGda = (gda, idSub, callback) => (dispatch, getState) => {
	dispatch(resetErrors());
	return requestFromServer
		.deleteCatalogToGdaCall(gda, idSub)
		.then((res) => {
			dispatch(
				removeCatalogToSelectedGda(idSub));
			callback && callback();
			dispatch(
				setSuccessErrorAction(
					"Catalogo disassociato con successo al gruppo di acquisto"
				)
			);
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction(
					"Errore nell'associazione del catalogo al gruppo di acquisto"
				)
			);
		});
};

export const addUserGda = (id, gda, data, callback) => (dispatch, getState) => {
	dispatch(resetErrors());
	const usersAddable = getState().gda.addableUsers;
	return requestFromServer
		.addUserToGda(id, gda, data.user, data.roles)
		.then((res) => {
			dispatch(
				addUserToSelectedGda({
					id: data.user,
					...((usersAddable || []).find((ele) => ele.id === data.user) || {}),
					roles: data.roles.map((ele) => ({ name: ele })),
				})
			);
			callback && callback();
			dispatch(
				setSuccessErrorAction(
					"Utente aggiunto con successo al gruppo di acquisto"
				)
			);
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction(
					"Errore nell'aggiunta dell'utente al gruppo di acquisto"
				)
			);
		});
};

export const removeUserGda = (id, gda, user, roles = []) => (dispatch) => {
	const buttonList = [
		{
			label: "Annulla",
			action: "cancelDelete",
			type: "secondary",
			dataType: {},
		},
		{
			label: "Conferma",
			action: "confirmDelete",
			type: "primary",
			dataType: {},
		},
	];
	const confirmDelete = () => {
		requestFromServer
			.removeUserToGda(id, gda, user.id)
			.then((res) => {
				dispatch(removeUserToSelectedGda(user));
				dispatch(
					setSuccessErrorAction(
						"Utente rimosso con successo dal gruppo di acquisto"
					)
				);
			})
			.catch((error) => {
				dispatch(
					setErrorTypeAction(
						"Errore nella rimozione dell'utente dal gruppo di acquisto"
					)
				);
			});
		dispatch(resetModal());
	};
	const cancelDelete = () => {
		dispatch(resetModal());
	};
	dispatch(
		setModal(
			buttonList,
			{
				title: "Cancella Utente dal gruppo di acquisto",
				body: `Sei sicuro di voler cancellare l'utente selezionato dal gruppo di acquisto?`,
			},
			{
				cancelDelete,
				confirmDelete,
			}
		)
	);
};

export const getAllRolesAction = () => (dispatch) => {
	return requestFromServer
		.getAllRoles()
		.then((res) => {
			dispatch(setRoles(res));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction("Errore nel caricamento dei ruoli"));
		});
};

export const updateGdaAction = (idCustomer, idGda, data) => (dispatch) => {
	dispatch(resetErrors());
	return requestFromServer
		.updateGda(idCustomer, idGda, data)
		.then((res) => {
			dispatch(updateSelectedGda(res));
			dispatch(
				setSuccessErrorAction("Gruppo di acquisto modificato con successo")
			);
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction("Errore nella modifica del gruppo di acquisto")
			);
		});
};
