import React, {useState} from "react";

import {ShowHideForm} from "../../shared/ui/forms/ShowHideForm";
import {FormCreateProduct} from "./FormCreateProduct";
import {configCloseForm} from "../../shared/ui/forms/dataForm";


export function CreateProduct() {
    const [config, setConfig]= useState();

  return (
      <ShowHideForm config={config} buttonLabel="Aggiungi Prodotto">
          <FormCreateProduct closeForm={() => setConfig(configCloseForm())} />
      </ShowHideForm>
  );
}
