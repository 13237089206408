import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { spinnerSlice } from "../app/actions/spinner/spinnerSlice";
import { usersSlice } from "../app/actions/users/usersSlice";
import { reducer as oidcReducer } from "redux-oidc";
import { customersSlice } from "./customers/customersSlice";
import { gdaSlice } from "./gda/gdaSlice";
import { productsSlice } from "./products/productsSlice";
import { catalogsSlice } from "./catalogs/catalogsSlice";
import { lpSlice } from "./lp/lpSlice";
import { errorsSlice } from "./errors/errorsSlice";
import loadReducer from "../app/shared/ui/loader/redux/loadSlice";
import modalReducer from "../app/shared/redux/modal/modalSlice";

export const rootReducer = combineReducers({
	auth: auth.reducer,
	spinner: spinnerSlice.reducer,
	customers: customersSlice.reducer,
	users: usersSlice.reducer,
	gda: gdaSlice.reducer,
	products: productsSlice.reducer,
	catalogs: catalogsSlice.reducer,
	lp: lpSlice.reducer,
	errors: errorsSlice.reducer,
	oidc: oidcReducer,
	load: loadReducer,
    modal: modalReducer,
});
