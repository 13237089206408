import React, {useEffect, useState} from "react";
import {FormElements} from "../../../shared/ui/forms/FormElements";
import {formStructureGda} from "./customerData";
import { Col, Form, Row} from "react-bootstrap";
import {useFormik} from "formik";
import {createGda} from "../../../../redux/gda/gdaActions";
import {useDispatch, useSelector} from "react-redux";
import SecondaryButton from "../../../shared/ui/buttons/secondary-button/SecondaryButton";
import PrimaryButton from "../../../shared/ui/buttons/primary-button/PrimaryButton";
import {validationSchemaGda} from "../index/customerData";
import {GetListTemplateRdoAction} from "../../../../redux/products/productsActions";
import {selectTemplatesRdo} from "../../../../redux/products/productsSlice";
import {UseCustomerDetailCreateGda} from "./UseCustomerDetailCreateGda";

export function FormCustomerDetailCreateGda({closeForm}) {
    const dispatch = useDispatch();
    const templatesRdo = useSelector(selectTemplatesRdo);
    useEffect(() => {
        dispatch(GetListTemplateRdoAction());
    }, [])
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchemaGda,
        onSubmit: values => {
            dispatch(createGda(values, () => closeForm()))
        }
    });
    const {formStructure} = UseCustomerDetailCreateGda(templatesRdo)
  return (
          <Form onSubmit={formik.handleSubmit}>
              <FormElements structure={formStructure} formik={formik}></FormElements>
              <Row className="justify-content-end mb-3">
                  <Col xs="auto">
                      <SecondaryButton type="button" onClick={() => closeForm()}>
                          Annulla
                      </SecondaryButton>
                  </Col>
                  <Col xs="auto">
                      <PrimaryButton type="submit">Salva</PrimaryButton>
                  </Col>
              </Row>
          </Form>
  );
}
