export function UseCustomerDetail(lpsub = [], types=[]) {
	const formStructureEditCustomer = [
		{
			name: "name",
			label: "Nome",
			type: "input",
			col: { sm: "6", lg: "3" },
		},
		{
			name: "erp",
			label: "Codice ERP",
			type: "input",
			col: { sm: "6", lg: "2" },
		},

		{
			name: "state",
			label: "Stato",
			type: "radio",
			format: "boolean",
			options: [
				{
					label: "Attivo",
					value: true,
				},
				{
					label: "Disattivo",
					value: false,
				},
			],
			col: { sm: "6", lg: "2" },
		},
		{
			name: "type",
			label: "Tipo",
			type: "select",
			format: "number",
			options: types.map(({ id: value, label }) => ({ value, label })),
			placeholder: "Seleziona tipologia",
			col: { sm: "6", lg: "2" },
		},
		{
			name: "lp-sub",
			label: "Listino prezzi associato",
			type: "select",
			placeholder: "Seleziona Listino prezzi generale",
			options: lpsub.map((ele) => ({ value: ele.id, label: ele.name })),
		},
		{
			name: "description",
			label: "Breve Descrizione",
			type: "textarea",
			col: { sm: "12", lg: "6" },
		},
		{
			name: "note",
			label: "Note",
			type: "textarea",
			col: { sm: "12", lg: "6" },
		},
	];
	return {
		formStructureEditCustomer,
	};
}
