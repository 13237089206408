import React, {useEffect} from "react";
import {Card, CardBody, CardHeader} from "../../../_metronic/_partials/controls";
import {useDispatch, useSelector} from "react-redux";
import {LpGenTable} from "./lpgen/LpGenTable";
import {CreateLpGen} from "./lpgen/CreateLpGen";
import {selectLpGenValidity, selectLpSub} from "../../../redux/lp/lpSlice";
import {GetLpGenValidityListAction, GetLpSubList} from "../../../redux/lp/lpActions";
import {LpSubTable} from "./lpsub/LpSubTable";
import {CreateLpSub} from "./lpsub/CreateLpSub";
import IsPermission from "../../shared/utils/IsPermission";
import {permissionsData} from "../../modules/Auth/_redux/permissionsData";

export function LpPage({ history }) {
    const dispatch = useDispatch();
    const lpGenList = useSelector(selectLpGenValidity);
    const lpSubList = useSelector(selectLpSub)
    useEffect(() => {
        dispatch(GetLpGenValidityListAction());
        dispatch(GetLpSubList())
    }, [])
  return (
      <div className="row">
        <div className="col-12">
          <Card>
            <CardHeader title="Lista validità LpGen"/>
            <CardBody>
                <IsPermission permissions={[permissionsData.lpEdit]} >
                    <CreateLpGen />
                </IsPermission>
                <LpGenTable list={lpGenList} />
            </CardBody>
          </Card>
            <Card>
                <CardHeader title="Lista listino prezzi associato"/>
                <CardBody>
                    <IsPermission permissions={[permissionsData.lpEdit]} >
                        <CreateLpSub />
                    </IsPermission>
                    <LpSubTable list={lpSubList} />
                </CardBody>
            </Card>
        </div>
      </div>
  );
}
