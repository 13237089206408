import {createSlice} from "@reduxjs/toolkit";

const initialCustomersState = {
  lpGenValidity: [],
  lpSub: [],
  selectedLpSub: undefined,
  selectedLpGenValidity: undefined,
  selectedLpSubValidity: undefined
};


export const lpSlice = createSlice({
  name: "lp",
  initialState: initialCustomersState,
  reducers: {
    // listProducts
    setLpGenValidity: (state, action) => {
      state.lpGenValidity = action.payload; // lista validity lpgen
    },
    addLpGenValidity: (state, action) => {
      state.lpGenValidity = [...state.lpGenValidity, action.payload]; // lista validity lpgen
    },
    setSelectedLpGenValidity: (state, action) => {
       state.selectedLpGenValidity = action.payload;  //  Corrente lista di validità selezionata
    },
    setLpSub: (state, action) => {
      state.lpSub = action.payload; //lista lpsub
    },
    addLpSub: (state, action) => {
      state.lpSub = [...state.lpSub, action.payload]; // aggiunge elemento alla lista di lpsub
    },
    removeLpSub: (state, action) => {
      state.lpSub = state.lpSub.filter(ele => ele.id !== action.payload.id); // rimuove elemento alla lista di lpSub
    },
    setSelectedLpSub: (state, action) => {
      state.selectedLpSub = action.payload; //lista validity del lpSub selezionato
    },
    addValiditySelectedLpSub: (state, action) => {
      state.selectedLpSub = {...state.selectedLpSub, list: [...state.selectedLpSub.list, action.payload]}; //Aggiungere elemento alla lista validity del lpSub selezionato
    },
    resetSelectedLpSub: (state) => {
      state.selectedLpSub = undefined; //lista validity del lpSub selezionato
    },
    setSelectedLpSubValidity: (state, action) => {
      state.selectedLpSubValidity = action.payload;  //  Corrente lista di validità selezionata
    },
    updateLpSub: (state, action) => {
      state.selectedLpSub = action.payload; //update lpSub
      state.lpSub = state.lpSub.map(ele => ele.id === action.payload.id ? action.payload : ele)
    }
  }
});

export const {updateLpSub, setSelectedLpSub, setSelectedLpSubValidity, addValiditySelectedLpSub, resetSelectedLpSub, addLpGenValidity, setSelectedLpGenValidity, removeLpSub, addLpSub, setLpSub, setLpGenValidity} = lpSlice.actions;

export const selectLpGenValidity = (state) => state.lp.lpGenValidity;
export const selectSelectedLpGenValidity = (state) => state.lp.selectedLpGenValidity;
export const selectSelectedLpSubValidity = (state) => state.lp.selectedLpSubValidity;
export const selectLpSub = (state) => state.lp.lpSub;
export const selectSelectedLpSub = (state) => state.lp.selectedLpSub;
