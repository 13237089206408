import React from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import {FormElements} from "../../shared/ui/forms/FormElements";
import {formStructureCatalog, validationSchemaCatalog} from "./catalogsData";
import {Form} from "react-bootstrap";
import {CreateCatalogAction} from "../../../redux/catalogs/catalogsActions";
import {RowSaveCancel} from "../../shared/ui/forms/RowSaveCancel";


export function FormCreateCatalog({closeForm}) {
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchemaCatalog,
        onSubmit: values => {
            dispatch(CreateCatalogAction(values, () => closeForm()))
        }
    });
  return (
      <Form onSubmit={formik.handleSubmit}>
          <FormElements structure={formStructureCatalog} formik={formik}></FormElements>
          <RowSaveCancel closeForm={closeForm} />
      </Form>
  );
}
