import * as requestFromServer from "./productsCrud";
import {
	addCatalogToSelectedProduct,
	addProduct,
	removeCatalogToSelectedProduct,
	removeProduct,
	setCategories,
	setProducts,
	setSelectedProduct,
	setTemplates, setTemplatesRdo,
	setTypes,
	updateProduct,
} from "./productsSlice";
import { objDeleteProperties } from "../../app/utils/objUtils";
import {
	setErrorTypeAction,
	setSuccessErrorAction,
} from "../errors/errorsActions";
import { resetErrors } from "../errors/errorsSlice";
import { addSelectedProducts } from "../catalogs/catalogsSlice";
import {
	addProductToCatalogCall,
	removeProductToCatalogCall,
} from "../catalogs/catalogsCrud";
import { resetModal, setModal } from "../../app/shared/redux/modal/modalSlice";

export const GetListTypeAction = () => (dispatch) => {
	return requestFromServer
		.getListType()
		.then((response) => {
			dispatch(setTypes(response));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction("Errore nel caricamento dei tipi prodotti"));
		});
};

export const GetListTemplateAction = () => (dispatch) => {
	return requestFromServer
		.getListTemplates()
		.then((response) => {
			dispatch(setTemplates(response));
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction("Errore nel caricamento del template prodotti")
			);
		});
};

export const GetListTemplateRdoAction = () => (dispatch) => {
	return requestFromServer
		.getListTemplatesRdo()
		.then((response) => {
			dispatch(setTemplatesRdo(response));
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction("Errore nel caricamento del template prodotti")
			);
		});
};

export const GetListCategoriesAction = () => (dispatch) => {
	return requestFromServer
		.getListCategories()
		.then((response) => {
			dispatch(setCategories(response));
		})
		.catch((error) => {
			dispatch(
				setErrorTypeAction("Errore nel caricamento delle categorie prodotti")
			);
		});
};

export const GetAllProducts = () => (dispatch) => {
	return requestFromServer
		.getProducts()
		.then((response) => {
			dispatch(setProducts(response));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction("Errore nel caricamento dei prodotti"));
		});
};

export const GetProductAction = (id) => (dispatch) => {
	return requestFromServer
		.getProduct(id)
		.then((response) => {
			delete response.Template;
			dispatch(setSelectedProduct(response));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction("Errore nel caricamento del prodotto"));
		});
};

export const CreateProductAction = (data, callback) => (dispatch) => {
	dispatch(resetErrors());
	return requestFromServer
		.createProduct(data)
		.then((response) => {
			dispatch(addProduct(response));
			callback && callback();
			dispatch(setSuccessErrorAction("Prodotto creato con successo"));
		})
		.catch((err) => {
			if(err?.response?.data?.Message === 'ERR_Product_ERP_DUPLICATE'){
				dispatch(setErrorTypeAction("Codice ERP già esistente"));
			} else if(err?.response?.data?.Message === 'ERR_Product_CodeSG_DUPLICATE'){
				dispatch(setErrorTypeAction("Codice SG già esistente"));
			}
			else {
				dispatch(setErrorTypeAction("Errore nel salvataggio del prodotto"));
			}
		});
};

export const UpdateProductAction = (data, callback) => (dispatch) => {
	dispatch(resetErrors());
	const copyData = objDeleteProperties(data, [
		"cSubs",
		"infoUpdate",
	]);
	return requestFromServer
		.updateProductCall(copyData)
		.then((response) => {
			dispatch(updateProduct(response));
			callback && callback();
			dispatch(setSuccessErrorAction("Prodotto modificato con successo"));
		})
		.catch((err) => {
			if(err?.response?.data?.Message === 'ERR_Product_ERP_DUPLICATE'){
				dispatch(setErrorTypeAction("Codice ERP già esistente"));
			} else if(err?.response?.data?.Message === 'ERR_Product_CodeSG_DUPLICATE'){
				dispatch(setErrorTypeAction("Codice SG già esistente"));
			}
			else {
				dispatch(setErrorTypeAction("Errore nella modifica del prodotto"));
			}
		});
};

export const DeleteProductAction = (id) => (dispatch) => {
	dispatch(resetErrors());
	const buttonList = [
		{
			label: "Annulla",
			action: "cancelDelete",
			type: "secondary",
			dataType: {},
		},
		{
			label: "Conferma",
			action: "confirmDelete",
			type: "primary",
			dataType: {},
		},
	];
	const confirmDelete = () => {
		requestFromServer
			.deleteProductCall(id)
			.then((response) => {
				dispatch(removeProduct(id));
				dispatch(setSuccessErrorAction("Prodotto eliminato con successo"));
			})
			.catch((error) => {
				dispatch(setErrorTypeAction("Errore nell'eliminazione del prodotto"));
			});
		dispatch(resetModal());
	};
	const cancelDelete = () => {
		dispatch(resetModal());
	};
	dispatch(
		setModal(
			buttonList,
			{
				title: "Cancella Prodotto",
				body: `Sei sicuro di voler cancellare il prodotto selezionato?`,
			},
			{
				cancelDelete,
				confirmDelete,
			}
		)
	);
};

export const AddCatalogToProductAction = (id, callback) => (
	dispatch,
	getState
) => {
	dispatch(resetErrors());
	const productId = getState().products.selectedProduct.id;
	return addProductToCatalogCall(id, productId)
		.then((response) => {
			callback && callback();
			const catalog = getState().catalogs.entities.find((cat) => cat.id === id);
			dispatch(addCatalogToSelectedProduct(catalog));
			dispatch(setSuccessErrorAction("Catalogo associato con successo"));
		})
		.catch((error) => {
			dispatch(setErrorTypeAction("Errore nell'associazione con il catalogo"));
		});
};

export const RemoveCatalogToProductAction = (id) => (dispatch, getState) => {
	dispatch(resetErrors());
	const productId = getState().products.selectedProduct.id;

	const buttonList = [
		{
			label: "Annulla",
			action: "cancelDelete",
			type: "secondary",
			dataType: {},
		},
		{
			label: "Conferma",
			action: "confirmDelete",
			type: "primary",
			dataType: {},
		},
	];
	const confirmDelete = () => {
		removeProductToCatalogCall(id, productId)
			.then((response) => {
				const catalog = getState().catalogs.entities.find(
					(cat) => cat.id === id
				);
				dispatch(removeCatalogToSelectedProduct(catalog));
				dispatch(setSuccessErrorAction("Catalogo disassociato con successo"));
			})
			.catch((error) => {
				dispatch(
					setErrorTypeAction("Errore nella dissociazione con il catalogo")
				);
			});
		dispatch(resetModal());
	};
	const cancelDelete = () => {
		dispatch(resetModal());
	};
	dispatch(
		setModal(
			buttonList,
			{
				title: "Disassocia Catalogo",
				body: `Sei sicuro di voler disassociare il catalogo selezionato?`,
			},
			{
				cancelDelete,
				confirmDelete,
			}
		)
	);
};
