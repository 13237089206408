import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import * as routeName from "../../../routes/routeNameAuthType";

import {columnsLpGen} from "../lpData";
import {useRoute} from "../../../utils/useRoute";
import {NoRecordsFoundMessage, PleaseWaitMessage} from "../../../../_metronic/_helpers";

export function LpSubValidityTable({list = [], idSub}) {
  const {changeRoute} = useRoute();
  const getSelectRow = (props) => {
    return {
      mode: "checkbox",
      clickToSelect: true,
      hideSelectAll: true,
      hideSelectColumn: true,
      onSelect: (row, isSelect, rowIndex, e) => {
        changeRoute(routeName.lpSubDetailValidity,  {idSub: idSub, id: row?.id})
      },
    };
  }
  return (
    <>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                hover={true}
                keyField="id"
                data={list}
                columns={columnsLpGen}
                selectRow={getSelectRow({
                  list
                })}
              >
                <PleaseWaitMessage entities={list} />
                <NoRecordsFoundMessage entities={list} />
              </BootstrapTable>
    </>
  );
}
