
import http from "../../app/utils/http";
import {objDeleteProperties} from "../../app/utils/objUtils";

export const CUSTOMERS_URL = "customers";

export function getTypesCustomer() {
  return http.get('customer_types')
}
// CREATE =>  POST: add a new customer to the server
export function createCustomer(customer) {
  return http.post(CUSTOMERS_URL, customer);
}

// READ
export function getAllCustomers() {
  // il metodo ritorna tutti i clienti
  return http.get(CUSTOMERS_URL)
}

export function getCustomerById(customerId) {
  return  http.get(`${CUSTOMERS_URL}/${customerId}`)
}

// UPDATE => PUT: update the customer on the server
export function updateCustomer(customer) {
  const data = objDeleteProperties(customer, ['infoUpdate', 'id']);
  return http.put(`${CUSTOMERS_URL}/${customer.id}`, {...data, tipoCliente: 0})
}

// DELETE => delete the customer from the server
export function deleteCustomer(customerId) {
  return http.delete(`${CUSTOMERS_URL}/${customerId}`)
}
